import React, { Component } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { isPointInPolygon } from "geolib";
import appConfig from "../shared/appConfig/appConfig";
import Map from "../shared/modules/Map";
import "react-datepicker/dist/react-datepicker.css";
import { getAddress } from "../shared/modules/Address";
import { handlePostReq } from "../shared/services/AppendHeadersService";
import toasterService from "../shared/services/ToasterService";
import arrowIcon from "../assets/images/icons/arrow.png";
import moment from "moment-timezone";
import TooltipOverlay from "./TooltipOverlay";
import { AddressTextBox } from "../shared/modules/AutocompleteInput";
import { currencyUtils } from "../shared/utilities/currencyUtils";
import languageConstants from "../shared/appConfig/languageConstants";
import { dateUtils } from "../shared/utilities/dateUtils";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import defaultGeofence from "../shared/helpers/defaultGeofence";
import TripType from "../shared/modules/TripType";
import SelectBox from "../shared/modules/SelectBox";
import decodePolylines from "../shared/helpers/decodePolylines";
import loadUnloadingValues from "../shared/helpers/loadUnloadingValues";
import setMinMaxTimeValues from "../shared/helpers/setMinMaxTimeValues";
import ServiceLevelModal from "../shared/modules/ServiceLevelModal";
import ServiceLevel from "../shared/modules/ServiceLevel";
import GoogleCaptcha from "../shared/modules/GoogleCaptcha";
import ReactGA from "react-ga";
import EstimateInfo from "../shared/modules/EstimateInfo";
import { DeliveryTimeRange } from "../shared/helpers/DeliveryTimeRange";
import getFullAddressFromObject from "../shared/helpers/getFullAddressFromObject";
import PickupExists from "../shared/layouts/common/PickupExists";

import { CustomDatePicker } from "../shared/modules/CustomDatePicker";

/* Redux Import Start */
import { connect } from "react-redux";
import {
	setAppSetting,
	setActiveTab,
	setShowLoader,
	setFormIsFilled,
	setShowPrice,
} from "../shared/redux/actions/common.actions";
import {
	setPickupPoint,
	setMinDate,
	setGeofence,
	setDefaultGeofenceIndex,
	setPickupDate,
	setIsDuo,
	setLoadUnloadTimeShow,
	setDefaultAdd,
	setPickupAddObj,
	setPickupAdd,
	setDefaultPoint,
	setDefaultGeofence,
	setPckupTime,
	setTripTypeValue,
	setDropoffAdd,
	setLoadTimeValue,
	setDropoffPoint,
	setTimeZone,
	setEstimate,
	setDropoffAddObj,
	setPickupRequestID,
	setShowCheckbox,
	setDriverHelpCarryFieldMessage,
	setServiceLevel,
	resetDataOnPageChange,
	setServiceLevelValues,
	setDeliveryTime,
	setDistance,
	setPickupAddressess,
	setPartnerUpchargeValue,
	setEditPickup,
	setIsMuscleHelpRequired,
	setIsBoxTruck,
} from "../shared/redux/actions/getEstimate.actions";
import {
	setDeliveryDetailsInitialState,
	setPickupContactName,
	setPickupContactPhone,
	setBackToQuote,
} from "../shared/redux/actions/deliveryDetails.actions";
import { changeMessage } from "../shared/modules/DynamicMessage";
import globalConstants from "../shared/appConfig/globalConstants";

/* Redux Import End */

const styles = {
	pickupTimeOptions: {
		height: "300px",
	},
	pickupDateOptions: {
		height: "375px",
	},
};
class QuoteOnly extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errors: {},
			mileageCap: "",
			rerenderDropoff: true,
			dropoffAddCloseIcon: false,
			dropValue: "",
			pickValue: "",
			pickupAddCloseIcon: false,
			workingHours: true,
			pointer: true,
			color: false,
			menuDesc: "d-none",
			serviceTitle: "",
			loadUnloadTimeShow: false,
			tripType: "",
			editPickup: false,
			checked: false,
			showServiceModal: false,
			serviceLevelShow:
				this.props.appSetting.PartnerLocationSettings.PartnerHasServiceLevels,
			renderServiceLevel: true,
			captchaValue: "",
			expired: "false",
			addrHLDelivery: false,
			addrHLSolo: false,
			addrHLDuo: false,
			category: "",
			action: "",
			label: "",
			allowPickupEdit:
				this.props.appSetting.PartnerLocationSettings
					.DefaultPickupLocationInfo !== null &&
				this.props.appSetting.PartnerLocationSettings.DefaultPickupLocationInfo
					.AllowEdit === false
					? false
					: true,
			isVisibleSoloDuo:
				this.props.appSetting.PartnerLocationSettings.EstimatePageFields[0]
					.IsVisible,
			whatsNeededData:
				this.props.appSetting.PartnerLocationSettings.EstimatePageFields[0],
			showModal: false,
			heightRenderer: false,
			height: "",
			addressLabelArray: [],
			showAlertModal: false,
			BusinessPartnerDefaultAddressRef: "",
			PartnerOperationHours: this.props.appSetting.PartnerLocationSettings
				.PartnerOperationHours
				? this.props.appSetting.PartnerLocationSettings.PartnerOperationHours
				: [],
			PickupAddrSelected: false,
			DropoffAddrSelected: false,
		};
		this.tripTypeRef = React.createRef();
		this.deliveryAddRef = React.createRef();
		this.loadUnloadRef = React.createRef();
		this.pickUpDateRef = React.createRef();
		this.handlePickupDate = this.handlePickupDate.bind(this);
		this.validatePickupPlace = this.validatePickupPlace.bind(this);
		this.validateDropoffPlace = this.validateDropoffPlace.bind(this);
		this.myDiv = React.createRef();
		this.captchaComponent = React.createRef();
		this.soloRef = React.createRef();
		this.duoRef = React.createRef();
	}

	/**
	 * sendEvent()
	 * This function is used to send trigger event to GA
	 */
	sendEvent = async (val) => {
		await this.setState({
			category: `CTA ${val} Button`,
			action: "Button Click",
			label: val,
		});
		ReactGA.event(this.state);
	};

	/**
	 * resetBungiiData()
	 * This function is used Reset Bungii data on click of Start over
	 */
	resetBungiiData = async () => {
		try {
			/**
			 * Settings props
			 */
			const {
				appSetting,
				defaultAdd,
				showCheckbox,
				setFormIsFilled,
				resetDataOnPageChange,
				setServiceLevel,
			} = this.props;
			let defaultGeofenceVal = this.getGeofenceByAddress();
			let data = {
				defaultGeofenceIndex: defaultGeofenceVal,
				defaultGeofence: appSetting.GeofenceSettings[defaultGeofenceVal],
				timeZone:
					appSetting.GeofenceSettings[defaultGeofenceVal]["TimezoneIdentifier"],
			};
			/**
			 * Set redux state
			 */
			setFormIsFilled(false);
			resetDataOnPageChange(data);
			if (!this.state.serviceLevelShow) {
				setServiceLevel(false);
			}

			await this.setState({
				errors: "",
				formHasErrors: false,
				submitClicked: false,
				dropoffAddCloseIcon: false,
				isValid: false,
				pickupAddCloseIcon: !defaultAdd ? false : true,
				pointer: showCheckbox ? true : false,
				color: showCheckbox ? false : true,
				checked: showCheckbox ? false : true,
				addrHLDelivery: false,
				addrHLSolo: false,
				addrHLDuo: false,
				captchaValue: "",
				showAlertModal: false,
			});

			let that = this;
			setTimeout(() => {
				this.setState({
					heightRenderer: false,
					height: "",
				});
				that.setHeight();
			}, 500);
			this.loadStates(appSetting);
			if (this.captchaComponent.current) {
				this.captchaComponent.current.reset();
			}
		} catch (e) {
			console.log(e);
		}
	};

	/**
	 * getDefaultPickupAddress()
	 * This function is used to find the pickup address from the address array.
	 */
	getDefaultPickupAddress = () => {
		try {
			const {
				appSetting,
				setPickupAddressess,
				pickupAddressess,
				setPickupContactName,
				setPickupContactPhone,
				pickupContactName,
				pickupContactPhone,
			} = this.props;
			let defaultAddress = null;

			/*Fetch and store the list of default addressess*/
			if (
				appSetting.PartnerLocationSettings.DefaultPickupLocationInfo != null
			) {
				let pickupAddressArray =
					appSetting.PartnerLocationSettings.DefaultPickupLocationInfo.Address;
				let newPickupAddressArray = [];
				let pickupLabelArray = [];

				if (pickupAddressArray.length) {
					let address = "";
					pickupAddressArray.forEach(function (value, i) {
						address = getFullAddressFromObject(value);
						newPickupAddressArray.push(address);
						pickupLabelArray.push(value["AddressLabel"]);
					});
				}
				/**
				 * Set redux state
				 */
				setPickupAddressess(
					pickupAddressess.length ? pickupAddressess : newPickupAddressArray
				);
				defaultAddress = pickupAddressArray.filter((data) => data.IsDefault);
				if (defaultAddress[0]["DefaultStaticFields"].length) {
					setPickupContactName(
						pickupContactName
							? pickupContactName
							: JSON.stringify(defaultAddress[0]["DefaultStaticFields"][0])
					);
					setPickupContactPhone(
						pickupContactPhone
							? pickupContactPhone
							: JSON.stringify(defaultAddress[0]["DefaultStaticFields"][1])
					);
				} else {
					let pContactName = {
						FieldName: "Pickup contact name",
						FieldValue:
							appSetting.PartnerLocationSettings.StaticFields[0][
								"DefaultValue"
							],
					};
					let pContactPhone = {
						FieldName: "Pickup contact phone",
						FieldValue:
							appSetting.PartnerLocationSettings.StaticFields[1][
								"DefaultValue"
							],
					};
					setPickupContactName(
						pickupContactName ? pickupContactName : JSON.stringify(pContactName)
					);
					setPickupContactPhone(
						pickupContactPhone
							? pickupContactPhone
							: JSON.stringify(pContactPhone)
					);
				}
				this.setState({
					addressLabelArray: pickupLabelArray,
					BusinessPartnerDefaultAddressRef: this.state
						.BusinessPartnerDefaultAddressRef
						? this.state.BusinessPartnerDefaultAddressRef
						: defaultAddress[0]["BusinessPartnerDefaultAddressRef"],
				});

				return defaultAddress;
			} else {
				return defaultAddress;
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * getGeofenceByAddress()
	 * This function is used to select the geofence based on address
	 */
	getGeofenceByAddress = () => {
		try {
			const { geofence } = this.props;
			let newLat = "",
				newLng = "";

			let defaultAddr = this.getDefaultPickupAddress();

			if (defaultAddr) {
				newLat = defaultAddr[0].Location.Latitude;
				newLng = defaultAddr[0].Location.Longitude;
				for (let i = 0; i < geofence.length; i++) {
					var isValid = isPointInPolygon(
						{ latitude: newLat, longitude: newLng },
						geofence[i]
					);
					if (isValid) {
						return i;
					}
				}
				if (!isValid) {
					return defaultGeofence();
				}
			} else {
				return defaultGeofence();
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * getDefaultGeofence()
	 * This function is used to select the default geofence based on the flag: IsDefault=true
	 */
	getDefaultGeofence = async (settings) => {
		try {
			const {
				defaultGeofenceIndex,
				setDefaultGeofenceIndex,
				setDefaultGeofence,
				setTimeZone,
			} = this.props;

			if (defaultGeofenceIndex === null) {
				let defaultGeofenceVal = this.getGeofenceByAddress();
				/**
				 * Set redux state
				 */
				setDefaultGeofenceIndex(defaultGeofenceVal);
				setDefaultGeofence(settings[defaultGeofenceVal]);
				setTimeZone(settings[defaultGeofenceVal]["TimezoneIdentifier"]);
			} else {
				/**
				 * Set redux state
				 */
				setDefaultGeofenceIndex(defaultGeofenceIndex);
				setDefaultGeofence(settings[defaultGeofenceIndex]);
				setTimeZone(settings[defaultGeofenceIndex]["TimezoneIdentifier"]);
			}
		} catch (e) {
			console.log(e);
		}
	};

	/**
	 * setPartnerUpcharge()
	 * This function is used to set the prices based on the distance and the assigned SKU's
	 */
	setPartnerUpcharge = (miles) => {
		try {
			const { appSetting, tripType, setPartnerUpchargeValue } = this.props;
			const res =
				appSetting.PartnerLocationSettings.EstimatePageFields[0].PartnerUpcharge.filter(
					(val) => val.Driver === (tripType >= 2 ? 2 : 1)
				);
			let toAppend = "";
			// eslint-disable-next-line array-callback-return
			res.map((val) => {
				if (miles >= val.MinMileage && miles <= val.MaxMileage) {
					toAppend = val.AppendWithAmount
						? `${val.Amount} - ${val.AppendWithAmount}`
						: val.Amount;
					setPartnerUpchargeValue(`$${toAppend}`);
				}
			});
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * getDefaultService()
	 * This function is used to select the default service level based on the flag: IsDefault=1
	 */
	getDefaultService = async (settings) => {
		try {
			const {
				setServiceLevel,
				setDistance,
				setDeliveryTime,
				serviceLevelValues,
				appSetting,
			} = this.props;
			let defaultData = [];
			defaultData = settings.filter((data) => data.IsDefault);
			let timeRange = "";
			if (serviceLevelValues.length < 2) {
				setServiceLevel(settings[0]);
				setDistance(settings[0].DistancePickupToDropOff);
				timeRange = await DeliveryTimeRange();
				appSetting.PartnerLocationSettings?.EstimatePageFields[0]
					?.IsPartnerUpcharge &&
					this.setPartnerUpcharge(settings[0].DistancePickupToDropOff);
				await setDeliveryTime(timeRange);
			} else if (defaultData.length > 0) {
				setServiceLevel(defaultData[0]);
				setDistance(defaultData[0].DistancePickupToDropOff);
				timeRange = await DeliveryTimeRange();
				await setDeliveryTime(timeRange);
			} else {
				setServiceLevel(null);
				setDistance("");
			}
		} catch (e) {
			console.log(e);
		}
	};

	/**
	 * decodedPolyline()
	 * This function is used to decode all the polylines given in the API and store it in an Array()
	 */
	decodedPolyline = async (settings) => {
		try {
			const { setGeofence } = this.props;
			let decodePolylinesData = decodePolylines(settings);
			/**
			 * Set redux state
			 */
			setGeofence(decodePolylinesData);
		} catch (e) {
			console.log(e);
		}
	};

	/**
	 * loadStates()
	 * This function is used to load all the states on the initial run
	 */
	loadStates = async (settingInfo) => {
		/**
		 * Fetch Polylines
		 */
		await this.decodedPolyline(settingInfo.GeofenceSettings);

		if (settingInfo.GeofenceSettings.length > 0) {
			await this.getDefaultGeofence(settingInfo.GeofenceSettings);
		}
		const {
			defaultGeofence,
			setMinDate,
			pickupAdd,
			setPickupAdd,
			setIsDuo,
			setPickupPoint,
			pickupPoint,
			setDefaultPoint,
			estimate,
			setTimeZone,
			timeZone,
			setPickupAddObj,
			pickupAddObj,
			setDefaultAdd,
			defaultAdd,
			dropoffAdd,
			showCheckbox,
			setLoadTimeValue,
			pickupDate,
			tripType,
			loadTime,
			setShowPrice,
			setTripTypeValue,
			setServiceLevel,
			editPickupValue,
			setEditPickup
		} = this.props;

		/**
		 * Set default Timezone
		 */
		moment.tz.setDefault(defaultGeofence.TimezoneIdentifier);
		/**
		 * Fetch Polylines
		 */
		await this.decodedPolyline(settingInfo.GeofenceSettings);
		/**
		 * Set Address
		 */

		let defaultAddr = this.getDefaultPickupAddress();

		let address = pickupAdd ? pickupAdd : "";
		if (defaultAddr !== null) {
			let address2 = defaultAddr[0].Address2
				? defaultAddr[0].Address2 + ", "
				: "";
			let state = defaultAddr[0].State ? defaultAddr[0].State + ", " : "";
			let country = defaultAddr[0].Country ? defaultAddr[0].Country + ", " : "";
			address =
				defaultAddr[0].Address1 +
				", " +
				address2 +
				defaultAddr[0].City +
				", " +
				state +
				country +
				defaultAddr[0].ZipPostalCode;
		}

		try {
			let minDate = moment
				.utc()
				.tz(defaultGeofence.TimezoneIdentifier)
				.format("YYYY/MM/DD HH:mm:ss");
			minDate = new Date(minDate);
			setTripTypeValue(
				tripType
					? tripType
					: settingInfo.PartnerLocationSettings.TripConfigurableSettings &&
					  settingInfo.PartnerLocationSettings.TripConfigurableSettings
							.PartnerScheduledNoOfDrivers === "2" ? 2 : 1
			);
			await this.showDriverHelpCarryField();
			/**
			 * Set redux state
			 */
			setMinDate(minDate);
			setPickupAdd(pickupAdd ? pickupAdd : address);
			setIsDuo(defaultGeofence.ScheduledNoOfDrivers < 2 ? false : true);
			if (pickupPoint === "" || pickupPoint === undefined) {
				setPickupPoint(
					pickupPoint
						? pickupPoint
						: address
						? {
								lat: defaultAddr[0].Location.Latitude,
								lng: defaultAddr[0].Location.Longitude,
						  }
						: {
								lat: settingInfo.PartnerLocationSettings
									.BusinessPartnerLocationInfo.Location.Latitude,
								lng: settingInfo.PartnerLocationSettings
									.BusinessPartnerLocationInfo.Location.Longitude,
						  }
				);
			}
			setDefaultPoint(
				address && defaultAddr
					? {
							lat: defaultAddr[0].Location.Latitude,
							lng: defaultAddr[0].Location.Longitude,
					  }
					: {
							lat: settingInfo.PartnerLocationSettings
								.BusinessPartnerLocationInfo.Location.Latitude,
							lng: settingInfo.PartnerLocationSettings
								.BusinessPartnerLocationInfo.Location.Longitude,
					  }
			);
			setTimeZone(timeZone ? timeZone : defaultGeofence.TimezoneIdentifier);
			setPickupAddObj(
				pickupAddObj
					? pickupAddObj
					: address
					? JSON.stringify(defaultAddr[0])
					: JSON.stringify(
							settingInfo.PartnerLocationSettings.BusinessPartnerLocationInfo
					  )
			);
			setDefaultAdd(defaultAdd ? defaultAdd : address);
			setLoadTimeValue(
				loadTime ? loadTime : settingInfo.PartnerLocationSettings.LoadUnloadTime
			);
			setShowPrice(settingInfo.PartnerLocationSettings.DisplayEstimate);
			if (!this.state.serviceLevelShow) {
				setServiceLevel(false);
			}
			setEditPickup(editPickupValue ? editPickupValue : address ? false : true);
			await this.setState({
				loadUnloadTimeShow:
					settingInfo.PartnerLocationSettings.LoadUnloadTime === 0
						? false
						: true,
				mileageCap: settingInfo.PartnerLocationSettings.MileageCap,
				dropoffAddCloseIcon: dropoffAdd ? true : false,
				pickupAddCloseIcon: address ? true : false,
				dropValue: dropoffAdd,
				pickValue: pickupAdd ? pickupAdd : address,
				minDate: minDate,
				pickupDate: pickupDate ? new Date(pickupDate) : minDate,
				tripType: tripType
					? tripType
					: settingInfo.PartnerLocationSettings.TripConfigurableSettings &&
					  settingInfo.PartnerLocationSettings.TripConfigurableSettings
							.PartnerScheduledNoOfDrivers === "2" ? 2 : 1,
				editPickup: address ? false : true,
				PickupAddrSelected: pickupAdd || address,
				DropoffAddrSelected: !!dropoffAdd,
			});
		} catch (e) {
			console.log(e);
		}
		if (this.state.loadUnloadTimeShow) {
			let loadUnloadValues = this.loadUnloadValues();
			this.setState({
				loadUnload: loadUnloadValues,
			});
		}

		await this.setCalDate();
		await this.setMinMaxTime();

		if (showCheckbox) {
			if (estimate !== null) {
				this.setState({
					pointer: false,
					checked: true,
				});
			} else {
				this.setState({
					pointer: true,
					checked: false,
				});
			}
		}
	};

	/**
	 * componentDidMount()
	 * This function will be called only after the first render.
	 */
	componentDidMount = async () => {
		const { appSetting } = this.props;
		let that = this;

		setTimeout(function () {
			that.setHeight();
		}, 700);
		if (appSetting) {
			await this.loadStates(appSetting);
		}
	};

	/**
	 * setCalDate()
	 * This function is used to find the date
	 */
	setCalDate = async () => {
		const { setMinDate, setPickupDate, appSetting, defaultGeofence, timeZone, isBoxTruck } =
			this.props;
		const max = appSetting.BungiiSettings.ScheduledPickupTo;
		let currentTime = moment.utc().tz(timeZone).format("HH:mm:ss").split(":");
		let tripConfigurableSettings =
			appSetting.PartnerLocationSettings.TripConfigurableSettings;
		currentTime =
			currentTime[0] * 3600000 + currentTime[1] * 60000 + currentTime[2] * 1000;
		let bufferTimeValue =
			isBoxTruck ? tripConfigurableSettings?.BoxTruckLeadTime ?? defaultGeofence.BoxTruckLeadTime
				: this.state.tripType === 1 && this.props.isMuscleHelpRequired !== true
				? tripConfigurableSettings &&
				  tripConfigurableSettings.SoloEarliestScheduledTime
					? tripConfigurableSettings.SoloEarliestScheduledTime
					: defaultGeofence.SoloEarliestScheduledTime
				: tripConfigurableSettings &&
				  tripConfigurableSettings.DuoEarliestScheduledTime
				? tripConfigurableSettings.DuoEarliestScheduledTime
				: defaultGeofence.DuoEarliestScheduledTime;
		let bufferTime = bufferTimeValue + currentTime;
		let calculatedDate = [];
		try {
			let nextDate = "";
			let maxDate = "";

			//Below code is to reset pickup date to default date.
			if (isBoxTruck) {
				let minDate = moment
					.utc()
					.tz(defaultGeofence.TimezoneIdentifier)
					.format(globalConstants.DateTimeFormat);
				minDate = new Date(minDate);
				setMinDate(minDate);
				setPickupDate(minDate)
			}

			if (bufferTime > max) {
				let daysToAdd =
					tripConfigurableSettings?.SoloEarliestScheduledTime ||
					tripConfigurableSettings?.DuoEarliestScheduledTime
						? bufferTimeValue / (1000 * 60 * 60) / 24
						: 1;
				nextDate = moment
					.utc()
					.tz(timeZone)
					.add(daysToAdd, "days")
					.format("YYYY/MM/DD HH:mm:ss");
				maxDate = moment
					.utc()
					.tz(timeZone)
					.add(appSetting.BungiiSettings.LatestScheduledTimeInDays, "days")
					.format("YYYY/MM/DD HH:mm:ss");
				maxDate = new Date(maxDate);
				nextDate = new Date(nextDate);
				await this.setState({
					workingHours: false,
					pickupDate: moment(this.state.pickupDate).isBetween(nextDate, maxDate)
						? new Date(this.state.pickupDate)
						: nextDate,
					minDate: nextDate,
					maxDate,
				});
				/**
				 * Set redux state
				 */
				setMinDate(nextDate);
				setPickupDate(
					moment(this.state.pickupDate).isBetween(nextDate, maxDate)
						? new Date(this.state.pickupDate)
						: nextDate
				);
			} else {
				let minDate = moment.utc().tz(timeZone).format("YYYY/MM/DD HH:mm:ss");
				minDate = new Date(minDate);
				let maxDate = moment
					.utc()
					.tz(timeZone)
					.add(appSetting.BungiiSettings.LatestScheduledTimeInDays - 1, "days")
					.format("YYYY/MM/DD HH:mm:ss");
				maxDate = new Date(maxDate);
				this.setState({
					workingHours: true,
					pickupDate: moment(
						dateUtils.getDate(this.state.pickupDate)
					).isBetween(
						dateUtils.getDate(minDate),
						dateUtils.getDate(maxDate),
						"minutes",
						"[]"
					)
						? this.state.pickupDate
						: minDate,
					minDate: minDate,
					maxDate,
				});
				/**
				 * Set redux state
				 */
				setMinDate(minDate);
				setPickupDate(
					moment(dateUtils.getDate(this.state.pickupDate)).isBetween(
						dateUtils.getDate(minDate),
						dateUtils.getDate(maxDate),
						"minutes",
						"[]"
					)
						? this.state.pickupDate
						: minDate
				);
			}

			/**
			 * Generate the Pickup date dropdown based on the LatestScheduledTimeInDays
			 */
			calculatedDate.push(new Date(this.state.minDate));
			let dateAsString = dateUtils.getDate(this.state.minDate);
			for (
				let i = 1;
				i <= appSetting.BungiiSettings.LatestScheduledTimeInDays - 1;
				i++
			) {
				let newCalDate = moment(dateAsString)
					.add(i, "days")
					.format("YYYY/MM/DD HH:mm:ss");
				newCalDate = new Date(newCalDate);
				calculatedDate.push(newCalDate);
			}

			if (this.state.PartnerOperationHours.length > 0) {
				/*Code to find which days are disabled and find the next day dynamically */
				let dayIndex = [];
				for (let i = 0; i < 5; i++) {
					dayIndex.push({
						Index: i,
						Disabled: !this.state.PartnerOperationHours.find(
							(day) =>
								day.Day ===
								moment(dateUtils.getDate(new Date(calculatedDate[i]))).format(
									"dddd"
								)
						),
					});
				}
				const disabledData = dayIndex.filter((item) => !item.Disabled);
				this.setState({
					pickupDate: calculatedDate[disabledData[0].Index],
				});
				setPickupDate(calculatedDate[disabledData[0].Index]);
			}
			await this.setState({
				calculatedDate: calculatedDate,
			});
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * editPickupLocation()
	 * This function is called on click of edit pickup location
	 */
	editPickupLocation = async () => {
		try {
			const {
				defaultAdd,
				setPickupAdd,
				pickupAddressess,
				editPickupValue,
				setEditPickup,
				setPickupPoint,
				defaultPoint,
			} = this.props;

			//Set default address back once switched from edit to saved in the pickup address.
			if (editPickupValue && pickupAddressess.length > 1) {
				setPickupAdd(defaultAdd);
				setPickupPoint(defaultPoint);
				this.clearField("dropoffAdd");
			}
			setEditPickup(!editPickupValue);
			this.setState({
				editPickup: true,
				errors: {
					...this.state.errors,
					pickupAdd: "",
					dropoffAdd: "",
				},
				pickValue:
					editPickupValue && pickupAddressess.length > 1
						? defaultAdd
						: this.state.pickValue,
				pickupAddCloseIcon:
					editPickupValue && pickupAddressess.length > 1 ? false : true,
			});
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * loadUnloadValues()
	 * This function is used to Load/unload time Values
	 */
	loadUnloadValues = () => {
		try {
			const { appSetting } = this.props;
			return loadUnloadingValues(appSetting);
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * setMinMaxTime()
	 * This function is used to set the min max time values
	 */
	setMinMaxTime = async (date = "") => {
		try {
			const { pickupTime, setPckupTime } = this.props;
			let items = setMinMaxTimeValues(date);
			let pickupTimeVal = pickupTime
				? items.find((item) => item.value === pickupTime)
				: "";
			if (pickupTimeVal) {
				pickupTimeVal = pickupTimeVal.value;
			} else if (items.length > 0) {
				pickupTimeVal = items[0].value;
				await this.resetEstimate();
			} else {
				pickupTimeVal = "";
			}

			this.setState({
				pickupTimeValues: items,
			});
			/**
			 * Set redux states
			 */
			setPckupTime(pickupTimeVal);
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * isValidAddress()
	 * This function is used to check if the address is valid or not
	 */
	isValidAddress = async (lat, lng) => {
		try {
			const {
				geofence,
				defaultGeofenceIndex,
				setDefaultGeofenceIndex,
				setTripTypeValue,
				appSetting,
			} = this.props;
			/**
			 * Code to check if the selected address falls under given polygon and returns true. If the address selected is of not the default polygon then re-render
			 */
			for (let i = 0; i < geofence.length; i++) {
				var isValid = isPointInPolygon(
					{ latitude: lat, longitude: lng },
					geofence[i]
				);

				if (isValid) {
					if (i !== defaultGeofenceIndex) {
						/**
						 * Set redux state
						 */
						setDefaultGeofenceIndex(i);
						setTripTypeValue(1);

						await this.setState({
							isValid: isValid,
						});

						/**
						 * Re-render all states again
						 */
						await this.loadStates(appSetting);
					} else {
						await this.setState({
							isValid: isValid,
						});
						/**
						 * Do not Re-render since index remains the same.
						 */
					}
					break;
				} else {
					await this.setState({
						isValid: isValid,
					});
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * validatePickupPlace()
	 * This function is used to validate the pickup place
	 */
	validatePickupPlace = async (place) => {
		const { setShowLoader } = this.props;
		/**
		 * react-google-autocomplete uses componentDidMount which renders only for the first time.
		 * So the Bounds are not updated second time.
		 * rerenderDropoff state is used to call componentDidMount again to update bounds for the second
		 * time once pickup is selected.
		 */
		this.setState({
			rerenderDropoff: false,
		});
		setShowLoader(true);
		const { setPickupAdd, setPickupPoint, setPickupAddObj, setFormIsFilled, setEditPickup } =
			this.props;
		try {
			if (!place.name) {
				let newLat = place.geometry.location.lat(),
					newLng = place.geometry.location.lng();

					let addressObj = getAddress(place);

					if (addressObj) {
						/**
						 * Set redux states
						 */
						setPickupAdd(addressObj.FullAddress);
						setPickupPoint({ lat: newLat, lng: newLng });
						setPickupAddObj(JSON.stringify(addressObj.FullAddressObj));
						setFormIsFilled(true);
						setEditPickup(true);

						await this.setState({
							pickValue: addressObj.FullAddress,
							editPickup: true,
							errors: {
								...this.state.errors,
								pickupAdd: "",
							},
							pickupAddCloseIcon: true,
							PickupAddrSelected: true,
						});
						this.resetEstimate();
					} else {
						/**
						 * Set redux states
						 */
						setPickupAdd("");
						setEditPickup(true);

						await this.setState({
							errors: {
								...this.state.errors,
								pickupAdd: appConfig.language.errors.inValidAddress.message,
							},
							pickupAddCloseIcon: false,
							PickupAddrSelected: false,
							pickValue: "",
						});
					}
				 
				this.setState({
					rerenderDropoff: true,
				});
			} else {
				/**
				 * Set redux states
				 */
				setPickupAdd("");

				this.setState({
					errors: {
						...this.state.errors,
						pickupAdd: "Pickup Address is invalid",
					},
					pickupAddCloseIcon: false,
					PickupAddrSelected: false,
					pickValue: "",
					rerenderDropoff: true,
				});
			}
		} catch (err) {
			/**
			 * Set redux states
			 */
			setPickupAdd("");

			this.setState({
				errors: {
					...this.state.errors,
					pickupAdd: "Pickup Address is invalid",
				},
				pickupAddCloseIcon: false,
				PickupAddrSelected: false,
				pickValue: "",
				rerenderDropoff: true,
			});
		}
		setShowLoader(false);
	};

	/**
	 * validateDropoffPlace()
	 * This function is used to validate the dropoff place
	 */
	validateDropoffPlace = async (place) => {
		const {
			setShowLoader,
			pickupAdd,
			setDropoffAdd,
			pickupPoint,
		} = this.props;
		setShowLoader(true);

		if (pickupAdd) {
			try {
				if (!place.name) {
					let newLat = place.geometry.location.lat(),
						newLng = place.geometry.location.lng();

					/*Check if the selected address falls under the geofence or no*/
					await this.isValidAddress(newLat, newLng);

					if (this.state.isValid) {
						await this.validateMaxMilage(place,newLat,newLng)
					}else{
						/*Check if the pickup address falls under the geofence or no*/
						await this.isValidAddress(pickupPoint.lat,pickupPoint.lng);

						if (this.state.isValid) {
							await this.validateMaxMilage(place,newLat, newLng)
						} else {
							/**
							 * Set redux states
							 */
							setPickupAdd("");
							setEditPickup(true);
							this.setState({
								errors: {
									...this.state.errors,
									dropoffAdd: appConfig.language.errors.inValidPickupPlace.message,
								},
								dropoffAddCloseIcon: false,
								DropoffAddrSelected: false,
								dropValue: "",
							});
						}
					}
				} else {
					/**
					 * Set redux state
					 */
					setDropoffAdd("");

					this.setState({
						errors: {
							...this.state.errors,
							dropoffAdd: appConfig.language.errors.inValidDropoffPlace.message,
						},
						dropoffAddCloseIcon: false,
						DropoffAddrSelected: false,
						dropValue: "",
					});
				}
			} catch (err) {
				console.log(err);
				/**
				 * Set redux state
				 */
				setDropoffAdd("");

				this.setState({
					errors: {
						...this.state.errors,
						dropoffAdd: appConfig.language.errors.inValidDropoffPlace.message,
					},
					dropoffAddCloseIcon: false,
					DropoffAddrSelected: false,
					dropValue: "",
				});
			}
		} else {
			/**
			 * Set redux state
			 */
			setDropoffAdd("");

			this.setState({
				errors: {
					...this.state.errors,
					pickupAdd: appConfig.language.errors.pickupAddressRequired.message,
				},
				dropoffAddCloseIcon: false,
				DropoffAddrSelected: false,
				dropValue: "",
			});
		}

		setShowLoader(false);
	};

	/**
	 * validateMaxMilage()
	 * This function is used to check if the pickup to dropoff address is within specified miles
	 */
	 validateMaxMilage = async (place,newLat,newLng) => {
		try {
			const {
				setDropoffAdd,
				setDropoffAddObj,
				setDropoffPoint,
				setFormIsFilled,
			} = this.props;
			let addressObj = getAddress(place);
			if (addressObj) {
				const serviceLevelResponse =
					await this.getServiceLevelBasedOnDistance(
						addressObj.FullAddressObj
					);
				/**
				 * If the pickup to dropoff address is within specified miles then proceed with setting the service levels.
				 * Otherwise check for errors.
				 */
				if (serviceLevelResponse.isWithinMaxMilage) {
					/**
					 * Set redux state
					 */
					setDropoffAdd(addressObj.FullAddress);
					setDropoffAddObj(JSON.stringify(addressObj.FullAddressObj));
					setDropoffPoint({ lat: newLat, lng: newLng });
					setFormIsFilled(true);

					await this.setState({
						dropValue: addressObj.FullAddress,
						errors: {
							...this.state.errors,
							dropoffAdd: "",
						},
						dropoffAddCloseIcon: true,
						addrHLDelivery: true,
						heightRenderer: false,
						height: "",
						DropoffAddrSelected: true,
					});
					await this.setHeight();
					if (this.state.submitClicked) {
						await this.validate();
					}
					this.resetEstimate();
				} else {
					/**
					 * Set redux state
					 */
					setDropoffAdd("");
					if (serviceLevelResponse.errorResponse.Code === 20015) {
						this.setState({
							errors: {
								...this.state.errors,
								dropoffAdd: changeMessage(
									serviceLevelResponse.errorResponse.Message,
									this.state.mileageCap
								),
							},
							dropoffAddCloseIcon: false,
							DropoffAddrSelected: false,
							dropValue: "",
						});
					} else {
						this.setState({
							errors: {
								...this.state.errors,
								dropoffAdd: "",
							},
							dropoffAddCloseIcon: false,
							DropoffAddrSelected: false,
							dropValue: "",
						});
					}
				}
			} else {
				/**
				 * Set redux state
				 */
				setDropoffAdd("");

				this.setState({
					errors: {
						...this.state.errors,
						dropoffAdd:
							appConfig.language.errors.inValidDropoffPlace.message,
					},
					dropoffAddCloseIcon: false,
					DropoffAddrSelected: false,
					dropValue: "",
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * handlePickupDate()
	 * This function is used to update the pickup date on change
	 */
	handlePickupDate = async (event) => {
		try {
			const { setPickupDate, setFormIsFilled, timeZone } = this.props;
			let selectedDate = moment(event.target.value, "MM DD YYYY")
				.utc()
				.tz(timeZone)
				.format("YYYY/MM/DD HH:mm:ss");
			let dateAfterConversion = new Date(selectedDate);
			this.setState({
				pickupDate: dateAfterConversion,
			});
			/**
			 * Set redux state
			 */
			setPickupDate(dateAfterConversion);
			setFormIsFilled(true);

			this.setMinMaxTime(dateAfterConversion);
			this.resetEstimate();
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * setTripType()
	 * This function is used to set the Trip Type
	 */
	setTripType = async (value) => {
		try {
			const {
				setTripTypeValue,
				showCheckbox,
				setFormIsFilled,
				setShowLoader,
				dropoffAdd,
				setIsBoxTruck,
			} = this.props;
			/**
			 * Set redux state
			 */
			setTripTypeValue(value);
			setFormIsFilled(true);
			setIsBoxTruck(false);

			await this.setCalDate();
			this.setMinMaxTime();
			this.resetEstimate();
			this.showDriverHelpCarryField();

			/**
			 * If Service level is enabled and if user toggles between Solo/Duo,
			 * pull all services from the server only when dropoff address is entered, for the updated prices
			 */
			if (this.state.serviceLevelShow && dropoffAdd) {
				setShowLoader(true);
				await this.getServiceLevelBasedOnDistance();
			}

			if (showCheckbox) {
				this.setState({
					pointer: true,
					checked: false,
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * resetEstimate()
	 * This function is used to reset the estimate
	 */
	resetEstimate = async () => {
		try {
			const { setEstimate } = this.props;
			/**
			 * Set redux state
			 */
			setEstimate(null);
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * clearField()
	 * This function is used to clear the fields on click of the close icon
	 */
	clearField = (field) => {
		try {
			const {
				setPickupAdd,
				setDropoffAdd,
				setDropoffPoint,
				setPickupPoint,
				defaultPoint,
				setServiceLevel,
				setDistance,
				setDeliveryTime,
				setServiceLevelValues,
			} = this.props;
			let markerPoint = field.slice(0, -3) + "Point";

			/**
			 * Set redux state
			 */
			if (field === "pickupAdd") {
				/**
				 * If service level is enabled.
				 */
				if (this.state.serviceLevelShow) {
					setServiceLevel(null);
				}
				setPickupAdd("");
				setPickupPoint(defaultPoint);
				this.setState({
					dropoffAddCloseIcon: false,
					pickValue: "",
					dropValue: "",
					PickupAddrSelected: false,
					DropoffAddrSelected: false,
					errors: {
						...this.state.errors,
						dropoffAdd: "",
					},
				});
			}
			if (field === "dropoffAdd") {
				/**
				 * If service level is enabled.
				 */
				if (this.state.serviceLevelShow) {
					setServiceLevel(null);
				}
				this.setState({
					dropValue: "",
					DropoffAddrSelected: false,
				});
			}
			setDropoffAdd("");
			setDropoffPoint("");
			setDistance("");
			setDeliveryTime("");
			setServiceLevelValues("");
			this.setState({
				[markerPoint]: "",
				[field + "CloseIcon"]: false,
				[field + "Value"]: false,
				addrHLDelivery: false,
			});
			var that = this;
			setTimeout(() => {
				this.setState({
					heightRenderer: false,
					height: "",
				});
				that.setHeight();
			}, 500);
			this.resetEstimate();
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * setLoadTime()
	 * This function is used to update the Load time on change
	 */
	setLoadTime = async (event) => {
		try {
			const { setLoadTimeValue, setFormIsFilled } = this.props;
			let updatedLoadTime = event.target.value;
			/**
			 * Set redux state
			 */
			setLoadTimeValue(updatedLoadTime);
			setFormIsFilled(true);

			await this.setState({
				errors: {
					...this.state.errors,
					loadTime: "",
				},
			});

			if (this.state.submitClicked) {
				await this.validate();
			}
			await this.resetEstimate();
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * setPickupTime()
	 * This function is used to update the pickup time on change
	 */
	setPickupTime = async (event) => {
		try {
			const { setPckupTime, setFormIsFilled, setDeliveryTime, serviceLevel } =
				this.props;

			/**
			 * Set redux state
			 */
			setPckupTime(event.target.value);
			setFormIsFilled(true);
			this.setState({
				errors: {
					...this.state.errors,
					pickupTime: "",
				},
			});
			if (serviceLevel) {
				let timeRange = await DeliveryTimeRange(event.target.value, "");
				await setDeliveryTime(timeRange);
			}
			this.resetEstimate();
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * continueBungii()
	 * This function is used to go on the delivery-details page.
	 */
	continueBungii = async () => {};

	/**
	 * showDriverHelpCarryField()
	 * This function is used to show disclaimer message if Driver helper carry fields are present.
	 */
	showDriverHelpCarryField = () => {
		try {
			const {
				appSetting,
				tripType,
				setShowCheckbox,
				setDriverHelpCarryFieldMessage,
			} = this.props;
			let DriverHelpCarryFields =
				appSetting.PartnerLocationSettings.DriverHelpCarryFields;
				const noOfDrivers = tripType === 1 ? "solo" : "duo";
			DriverHelpCarryFields.forEach(function (value, i) {
				if (noOfDrivers === value.FieldName.toLowerCase() && value.IsRequired) {
					/**
					 * Set redux state
					 */
					setShowCheckbox(value.IsRequired);
					setDriverHelpCarryFieldMessage(value.FieldValue);
				} else if (
					noOfDrivers === value.FieldName.toLowerCase() &&
					!value.IsRequired
				) {
					/**
					 * Set redux state
					 */
					setShowCheckbox(false);
					setDriverHelpCarryFieldMessage("");
				}
			});
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * handleCloseIcon()
	 * This function is used to show the close icon.
	 */
	handleCloseIcon = (id) => {
		try {
			if (id === "pickupAdd") {
				this.setState({
					pickupAddCloseIcon: true,
				});
			} else if (id === "dropoffAdd") {
				this.setState({
					dropoffAddCloseIcon: true,
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * setTooltip()
	 * This function is used to set the tooltip.
	 */
	setTooltip = async (name) => {
		try {
			await this.setState({
				[name]: !this.state[name],
			});
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * hideTooltip()
	 * This function is used to hide the tooltip.
	 */
	hideTooltip = async (name) => {
		try {
			await this.setState({
				[name]: false,
			});
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * disableKeys()
	 * This function is used to Disable all the keys if state if not empty.
	 */
	disableKeys = (event, type) => {
		const { pickupAdd, dropoffAdd } = this.props;
		if (type === "dropoffAdd") {
			if (dropoffAdd !== "") {
				event.preventDefault();
			}
		}
		if (type === "pickupAdd") {
			if (pickupAdd !== "") {
				event.preventDefault();
			}
		}
	};

	/**
	 * checkboxSelected()
	 * This function is used to check if the checkbox is selected.
	 */
	checkboxSelected = async () => {
		try {
			if (this.state.checked === false) {
				this.setState({
					color: false,
					checked: true,
				});
				if (Object.keys(this.state.errors).length === 0) {
					this.setState({
						formHasErrors: false,
					});
				}
			} else {
				this.setState({
					color: true,
					checked: false,
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * getServiceLevelBasedOnDistance()
	 * This function is used to get all the services along with its pricing
	 * based on number of drivers, if the pickup to dropoff address is within the milage cap.
	 */
	getServiceLevelBasedOnDistance = async (locationObj = "", isMuscleHelpRequired = "") => {
		try {
			const {
				accessToken,
				setShowLoader,
				pickupAddObj,
				appSetting,
				dropoffAddObj,
				tripType,
			} = this.props;
			let {IsMuscleHelpEnabled} = appSetting?.PartnerLocationSettings;

			setShowLoader(true);
			/*Set renderServiceLevel false so that services can be re-rendered */
			this.setState({
				renderServiceLevel: false,
			});
			let pkAddr = JSON.parse(pickupAddObj);
			let dpAddr = locationObj ? locationObj : JSON.parse(dropoffAddObj);
			let defaultAddr = this.getDefaultPickupAddress();

			let body = {
				PickupLocation: pickupAddObj
					? pkAddr.Location
					: defaultAddr[0].Location,
				DropoffLocation: dpAddr.Location,
				PricingModelConfigVersionRef:
					appSetting.PartnerLocationSettings.PricingModelConfigVersionRef,
			};

			tripType > 2 ? body.NoOfPallets = tripType : body.NoOfDrivers = tripType;

			isMuscleHelpRequired = isMuscleHelpRequired !== "" 
				? isMuscleHelpRequired : this.props.isMuscleHelpRequired

			if(tripType === 1 && IsMuscleHelpEnabled && isMuscleHelpRequired)
				body.NoOfDrivers = 2;

			const serviceLevelPricingData = await handlePostReq(
				appConfig.urls.serviceLevels,
				body,
				accessToken
			);

			/**
			 * If serviceLevel is enabled and pickup to dropoff distance estimate in miles is less then milage cap miles then set service levels and return true
			 * If serviceLevel is disabled and pickup to dropoff distance estimate in miles is less then milage cap miles then directly return true
			 * Otherwise return false
			 */
			if (
				serviceLevelPricingData !== undefined &&
				serviceLevelPricingData.data.Error === null
			) {
				const { setServiceLevelValues } = this.props;

				if (this.state.serviceLevelShow) {
					/**
					 * Set redux state
					 */
					setServiceLevelValues(
						serviceLevelPricingData.data.ServicePricingModels
					);

					await this.getDefaultService(
						serviceLevelPricingData.data.ServicePricingModels
					);

					this.setState({
						renderServiceLevel: true,
					});
				}

				setShowLoader(false);
				return {
					isWithinMaxMilage: true,
				};
			} else {
				/**
				 * Distance from pickup to drop off is more then milage cap or error is returned from servicelevel API
				 * Application Error with Code= 20000
				 * No Data Error with Code= 20007
				 * Max Distance Error with Code=20015
				 */
				if (serviceLevelPricingData.data.Error.Code !== 20015) {
					toasterService.showCustomToasty(
						serviceLevelPricingData.data.Error.Message,
						"error"
					);
				}
				setShowLoader(false);
				return {
					isWithinMaxMilage: false,
					errorResponse: serviceLevelPricingData.data.Error,
				};
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * getServiceLevelsForVanityItems()
	 * This function is used to set isMuscleHelpRequired flag based on user input
	 * and call getServiceLevelBasedOnDistance
	 */
	getServiceLevelsForVanityItems = (isMuscleHelpRequired) => {
		this.props.setIsMuscleHelpRequired(isMuscleHelpRequired)
		const {
			dropoffAdd,
		} = this.props;
		if(this.state.serviceLevelShow && dropoffAdd)
			this.getServiceLevelBasedOnDistance(undefined, isMuscleHelpRequired)
	}

	/**
	 * handleServiceModalClose()
	 * This function is used to close the service modal on click of close icon
	 */
	handleServiceModalClose = () => {
		this.setState({
			showServiceModal: false,
		});
	};

	/**
	 * onSelectCaptcha()
	 * This function will be called on click of the Captcha.
	 */
	onSelectCaptcha = async (value) => {
		this.setState({
			captchaValue: value,
			errors: {
				...this.state.errors,
				captchaValue: "",
			},
		});
		await this.validate();
		/*if value is null recaptcha expired*/
		if (value === null) this.setState({ expired: "true" });
	};

	/**
	 * setServiceValue()
	 * Update the service data once different service is selected.
	 */
	setServiceValue = async (event) => {
		try {
			event.preventDefault();
			const {
				serviceLevelValues,
				setServiceLevel,
				setShowLoader,
				setDeliveryTime,
				setDistance,
				tripType,
				setIsBoxTruck,
				appSetting,
				defaultGeofenceIndex,
				setBackToQuote,
			} = this.props;
			let {IsBoxTruckDeliveryEnabled} = appSetting?.PartnerLocationSettings;
			let updatedServiceRef = event.target.value;
			setShowLoader(true);
			let serviceData = [];
			serviceData = serviceLevelValues.filter(
				(data) => data.ServiceRef === updatedServiceRef
			);
			setServiceLevel(serviceData[0]);
			//if number of pallets >= 3 && IsBoxTruckDeliveryEnabled && SL=== threshhold && tripType >= serviceData[0]?.MinNoOfPallets then set next day's date and time .
			if (tripType >= 3) {
				if (IsBoxTruckDeliveryEnabled && appSetting?.GeofenceSettings[defaultGeofenceIndex]?.BoxTruckLeadTime && serviceData[0]?.BoxTruckMinPallets && tripType >= serviceData[0]?.BoxTruckMinPallets) setIsBoxTruck(true)
				else setIsBoxTruck(false);
				setBackToQuote(false);
				await this.setCalDate();
				await this.setMinMaxTime();
				//Get the latest timeslots based on the date selected.
				if (this.state.slotEnabled) {
					this.getLatestTimeSlots();
				}
			}else setIsBoxTruck(false);

			let timeRange = await DeliveryTimeRange();
			await setDeliveryTime(timeRange);
			setDistance(serviceData[0].DistancePickupToDropOff);
			this.setPartnerUpcharge(serviceData[0].DistancePickupToDropOff);
			this.setHeight();
			await this.setState({
				errors: {
					...this.state.errors,
					serviceLevel: "",
				},
			});

			if (this.state.submitClicked) {
				await this.validate();
			}
			setShowLoader(false);
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * changePickupAddress()
	 * This function is used to change the pickup address based on the dropdown selection.
	 */
	changePickupAddress = async (event) => {
		try {
			const {
				setPickupAdd,
				setPickupPoint,
				setPickupAddObj,
				setFormIsFilled,
				appSetting,
				setPickupContactName,
				setPickupContactPhone,
			} = this.props;
			let value = event.target.value;
			let selectedMenuItem = event.currentTarget.getAttribute("data-key");
			let addressess =
				appSetting.PartnerLocationSettings.DefaultPickupLocationInfo.Address;

			
				/**
				 * Set redux states
				 */
				if (addressess[selectedMenuItem]["DefaultStaticFields"].length) {
					setPickupContactName(
						JSON.stringify(
							addressess[selectedMenuItem]["DefaultStaticFields"][0]
						)
					);
					setPickupContactPhone(
						JSON.stringify(
							addressess[selectedMenuItem]["DefaultStaticFields"][1]
						)
					);
				} else {
					let pContactName = {
						FieldName: "Pickup contact name",
						FieldValue:
							appSetting.PartnerLocationSettings.StaticFields[0][
								"DefaultValue"
							],
					};
					let pContactPhone = {
						FieldName: "Pickup contact phone",
						FieldValue:
							appSetting.PartnerLocationSettings.StaticFields[1][
								"DefaultValue"
							],
					};
					setPickupContactName(JSON.stringify(pContactName));
					setPickupContactPhone(JSON.stringify(pContactPhone));
				}
				setPickupAdd(value);
				setPickupPoint({
					lat: addressess[selectedMenuItem].Location.Latitude,
					lng: addressess[selectedMenuItem].Location.Longitude,
				});
				setPickupAddObj(JSON.stringify(addressess[selectedMenuItem]));
				setFormIsFilled(true);
				this.clearField("dropoffAdd");
				this.setState({
					pickValue: value,
					errors: {
						...this.state.errors,
						pickupAdd: "",
						dropoffAdd: "",
					},
					pickupAddCloseIcon: true,
					BusinessPartnerDefaultAddressRef:
						addressess[selectedMenuItem].BusinessPartnerDefaultAddressRef,
				});
				this.resetEstimate();
			
			this.setState({
				rerenderDropoff: true,
			});
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * setHeight()
	 * This function is used to get the height of the element
	 */
	setHeight = () => {
		try {
			let currentHeight = this.myDiv.current.clientHeight;
			this.setState({
				heightRenderer: true,
				height: currentHeight,
			});
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * handleCloseAlert()
	 * This function is used to close the popup
	 */
	handleCloseAlert = () => {
		this.setState({
			showAlertModal: false,
		});
	};

	render() {
		const {
			pickupDate,
			pickupAdd,
			geofence,
			defaultGeofenceIndex,
			loadTime,
			isDuo,
			tripType,
			pickupPoint,
			dropoffPoint,
			defaultPoint,
			estimate,
			showCheckbox,
			driverHelpCarryFieldMessage,
			serviceLevel,
			appSetting,
			quickAccessUser,
			pickupAddressess,
			pickupRequestID,
			quoteOnlyUser,
			partnerUpchargeValue,
			editPickupValue
		} = this.props;
		let highlighter = [
			{ addrHLDelivery: this.state.addrHLDelivery },
			{ addrHLSolo: this.state.addrHLSolo },
			{ addrHLDuo: this.state.addrHLDuo },
		];
		return (
			<React.Fragment>
				<h1>
					{appSetting.PartnerLocationSettings.EstimatePageLabel}{" "}
					<span className="reset" onClick={this.resetBungiiData.bind(this)}>
						Start Over
					</span>
				</h1>
				<Row className="m-auto">
					<Col xs="12" lg="6" className="px-0 pr-lg-10">
						<Form
							ref={this.myDiv}
							className={
								this.state.isVisibleSoloDuo
									? "form-content"
									: "form-content form-wo-solo-duo"
							}
							noValidate
						>
							{/* Component TripType */}
							{this.state.isVisibleSoloDuo ? (
								<TripType
									showCheckbox={showCheckbox}
									tripTypeRef={this.tripTypeRef}
									setTooltip={this.setTooltip}
									showTripTypeTooltip={this.state.showTripTypeTooltip}
									tripType={tripType}
									setTripType={this.setTripType}
									isDuo={isDuo}
									hideTooltip={this.hideTooltip}
									driverHelpCarryFieldMessage={driverHelpCarryFieldMessage}
									handleChange={this.checkboxSelected}
									color={this.state.color}
									pointer={this.state.pointer}
									checked={this.state.checked}
									soloRef={this.soloRef}
									duoRef={this.duoRef}
									whatsNeededData={this.state.whatsNeededData}
									showSoloTooltip={this.state.showSoloTooltip}
									showDuoTooltip={this.state.showDuoTooltip}
									muscleHelpRequiredError={this.state.errors.muscleHelpRequiredError}
									getServiceLevelsForVanityItems={this.getServiceLevelsForVanityItems}
								/>
							) : (
								""
							)}

							<Form.Group className="position-relative">
								<Form.Label className="pickup">
									Pickup Address{" "}
									{this.state.allowPickupEdit ? (
										!editPickupValue ? (
											<span className="link" onClick={this.editPickupLocation}>
												{languageConstants.getEstimate.pickupAddressEditText1}
											</span>
										) : pickupAddressess && pickupAddressess.length > 1 ? (
											<span className="link" onClick={this.editPickupLocation}>
												{languageConstants.getEstimate.pickupAddressEditText2}
											</span>
										) : (
											""
										)
									) : (
										""
									)}
								</Form.Label>
								{/* Component Pickup address */}
								{pickupAddressess && pickupAddressess.length > 1 &&
								!editPickupValue ? (
									<FormControl
										variant="outlined"
										className="pickup-address form-control"
									>
										<SelectBox
											type="pickupAddress"
											items={pickupAddressess}
											value={pickupAdd}
											onChange={(place) => this.changePickupAddress(place)}
											errors={this.state.errors}
											addressLabelArray={this.state.addressLabelArray}
										/>
									</FormControl>
								) : (
									<React.Fragment>
										{editPickupValue ? (
											<AddressTextBox
												googleMapURL={appConfig.urls.GoogleMap_URL}
												loadingElement={<React.Fragment></React.Fragment>}
												containerElement={<React.Fragment></React.Fragment>}
												mapElement={<div></div>}
												value={this.state.pickValue}
												stateType={"pickValue"}
												setAddrinState={(val) => {
													this.setState({ pickValue: val });
													this.handleCloseIcon("pickupAdd");
												}}
												validatePlace={(place) =>
													this.validatePickupPlace(place)
												}
												errors={this.state.errors}
												geofence={geofence}
												// disablePaste={(event) => event.preventDefault()}
												noBackspaces={(event) =>
													this.disableKeys(event, "pickupAdd")
												}
												defaultGeofenceIndex={defaultGeofenceIndex}
												spanId="pickupAddCloseIcon"
												searchId="pickupAddSearchIcon"
												classCloseIcon={
													this.state.pickupAddCloseIcon
														? "clear-field d-block"
														: "clear-field d-none"
												}
												classSearchIcon={
													this.state.pickupAddCloseIcon
														? this.state.pickValue
															? "search-blue d-block"
															: "search-field d-block"
														: "search-field d-none"
												}
												onClick={this.clearField.bind(this, "pickupAdd")}
												center={{ lat: pickupPoint.lat, lng: pickupPoint.lng }}
												pickupAddrSelected={this.state.PickupAddrSelected}
											></AddressTextBox>
										) : (
											<address className="">{pickupAdd}</address>
										)}
									</React.Fragment>
								)}
								<div className="invalid-feedback d-block">
									{this.state.errors.pickupAdd}
								</div>
							</Form.Group>
							<Form.Group className="position-relative">
								<Form.Label className="dropoff">
									Delivery Address
									<i
										onClick={this.setTooltip.bind(
											this,
											"showDeliveryAddTooltip"
										)}
										ref={this.deliveryAddRef}
										className="infoIcon"
									/>
									<TooltipOverlay
										target={this.deliveryAddRef.current}
										showTooltip={this.state.showDeliveryAddTooltip}
										hideTooltip={this.hideTooltip.bind(
											this,
											"showDeliveryAddTooltip"
										)}
									>
										<p>Where should the item(s) be delivered?</p>
									</TooltipOverlay>
								</Form.Label>
								{/* Component Dropoff address */}
								{this.state.rerenderDropoff ? (
									<AddressTextBox
										googleMapURL={appConfig.urls.GoogleMap_URL}
										loadingElement={<React.Fragment></React.Fragment>}
										containerElement={<React.Fragment></React.Fragment>}
										mapElement={<div></div>}
										value={this.state.dropValue}
										stateType={"dropValue"}
										setAddrinState={(val) => {
											this.setState({ dropValue: val });
											this.handleCloseIcon("dropoffAdd");
										}}
										validatePlace={(place) => this.validateDropoffPlace(place)}
										errors={this.state.errors}
										geofence={geofence}
										// disablePaste={(event) => event.preventDefault()}
										noBackspaces={(event) =>
											this.disableKeys(event, "dropoffAdd")
										}
										defaultGeofenceIndex={defaultGeofenceIndex}
										spanId="dropoffAddCloseIcon"
										searchId="pickupAddSearchIcon"
										classCloseIcon={
											this.state.dropoffAddCloseIcon
												? "clear-field d-block"
												: "clear-field d-none"
										}
										classSearchIcon={
											this.state.dropoffAddCloseIcon
												? this.state.dropValue
													? "search-blue d-block"
													: "search-field d-block"
												: "search-field d-none"
										}
										onClick={this.clearField.bind(this, "dropoffAdd")}
										center={{ lat: defaultPoint.lat, lng: defaultPoint.lng }}
										dropoffAddrSelected={this.state.DropoffAddrSelected}
									></AddressTextBox>
								) : (
									""
								)}

								<div className="invalid-feedback d-block">
									{this.state.errors.dropoffAdd}
								</div>
							</Form.Group>
							{this.state.loadUnloadTimeShow ? (
								<Form.Group>
									<Form.Label>
										Load + Unload Time
										<i
											onClick={this.setTooltip.bind(
												this,
												"showLoadUnloadTooltip"
											)}
											ref={this.loadUnloadRef}
											className="infoIcon"
										/>
										<TooltipOverlay
											target={this.loadUnloadRef.current}
											showTooltip={this.state.showLoadUnloadTooltip}
											hideTooltip={this.hideTooltip.bind(
												this,
												"showLoadUnloadTooltip"
											)}
										>
											<p>
												Since every Bungii is different, you get to decide how
												much Load + Unload Time you need. Your estimate varies
												upon the time you select, which helps ensure accuracy.
											</p>
											<p>
												If you anticipate 30 mins of Load and 15 mins of Unload,
												select 45 mins. On average, delivering something like a
												couch to a ground-level room takes 15 mins for Load +
												Unload Time.
											</p>
											<p>
												We recommend increasing the Load + Unload Time if you
												have multiple items, items that require additional
												wrapping/securing, stairs or narrow doorways/halls.
											</p>
										</TooltipOverlay>
									</Form.Label>

									<FormControl variant="outlined" className="load-time">
										<SelectBox
											type="loadTime"
											class={this.state.errors.loadTime}
											value={loadTime}
											onChange={this.setLoadTime}
											items={this.state.loadUnload}
										/>
									</FormControl>
									<div className="invalid-feedback d-block">
										{this.state.errors.loadTime}
									</div>
								</Form.Group>
							) : (
								""
							)}

							{this.state.serviceLevelShow && (
								<ServiceLevel
									editPickup={editPickupValue}
									highlighter={highlighter}
									setTooltip={this.setTooltip}
									hideTooltip={this.hideTooltip}
									showServiceLevelTooltip={this.state.showServiceLevelTooltip}
									errors={this.state.errors.serviceLevel}
									setServiceValue={this.setServiceValue}
									title={"quoteOnly"}
								/>
							)}

							<Row className="col-padding">
								<Col xs="7">
									<Form.Label>
										Pickup Date
										<i
											onClick={this.setTooltip.bind(this, "showPickUpTooltip")}
											ref={this.pickUpDateRef}
											className="infoIcon"
										/>
										<TooltipOverlay
											target={this.pickUpDateRef.current}
											showTooltip={this.state.showPickUpTooltip}
											hideTooltip={this.hideTooltip.bind(
												this,
												"showPickUpTooltip"
											)}
										>
											<p>
												Please select a delivery date within the next{" "}
												{appSetting.PartnerLocationSettings
													.AdvanceScheduleDays ?? "30"}{" "}
												days.
											</p>
										</TooltipOverlay>
									</Form.Label>

									<FormControl
										variant="outlined"
										data-size="10"
										className="pickup-date"
									>
										{this.state.calculatedDate &&
											this.state.calculatedDate.length > 0 && (
												<CustomDatePicker
													value={pickupDate}
													items={this.state.calculatedDate}
													workingHours={this.state.workingHours}
													onChange={this.handlePickupDate}
													PartnerOperationHours={
														this.state.PartnerOperationHours
													}
												/>
											)}
									</FormControl>
								</Col>
							</Row>

							{quickAccessUser && (
								<GoogleCaptcha
									onSelectCaptcha={this.onSelectCaptcha}
									errors={this.state.errors.captchaValue}
									captchaComponent={this.captchaComponent}
								/>
							)}

							{this.state.serviceLevelShow ? (
								<Row id="estimate-section">
									<Col>
										{serviceLevel ? <EstimateInfo /> : ""}
										<div
											className={
												quoteOnlyUser
													? "estimate-quote-only estimate"
													: "estimate"
											}
										>
											<label>
												Estimated Cost:{" "}
												<strong>
													{serviceLevel
															? appSetting.PartnerLocationSettings
																	?.EstimatePageFields[0]?.IsPartnerUpcharge
																? partnerUpchargeValue
																: currencyUtils.formatCurrency(
																		serviceLevel.ServiceCost
																  )
														: languageConstants.defaultPrice.default}
												</strong>
											</label>
											{!quoteOnlyUser && (
												<button id="get-estimate" className="btn" type="submit">
													Continue
													<img src={arrowIcon} alt="Submit" />
												</button>
											)}
										</div>
									</Col>
								</Row>
							) : estimate ? (
								<Row id="estimate-section">
									<Col>
										<div className="estimate">
											<label>
												Estimated Cost: <strong>{estimate}</strong>
											</label>
											<button className="btn" onClick={this.continueBungii}>
												Continue <img src={arrowIcon} alt="Continue" />
											</button>
										</div>
										<EstimateInfo />
									</Col>
								</Row>
							) : (
								<button id="get-estimate" className="btn" type="submit">
									{appSetting.PartnerLocationSettings.EstimatePageLabel}
								</button>
							)}

							{this.state.formHasErrors ? (
								<Row>
									<Col
										sm="12"
										className="err-msg text-danger warning-icon mt-3"
									>
										Please verify the highlighted fields above.
									</Col>
								</Row>
							) : (
								""
							)}
						</Form>
					</Col>
					<Col sm="6" className="p-l-10 d-none d-lg-block">
						{pickupPoint && this.state.heightRenderer ? (
							<Map
								google={this.props.google}
								center={{ lat: pickupPoint.lat, lng: pickupPoint.lng }}
								zoom={15}
								pickupPoint={pickupPoint}
								dropoffPoint={dropoffPoint}
								address={pickupAdd}
								showCheckbox={showCheckbox}
								isVisibleSoloDuo={this.state.isVisibleSoloDuo}
								UIType={this.state.whatsNeededData.UIType}
								height={this.state.height + 30}
							/>
						) : defaultPoint && this.state.heightRenderer ? (
							<Map
								google={this.props.google}
								center={{ lat: defaultPoint.lat, lng: defaultPoint.lng }}
								zoom={15}
								address={pickupAdd}
								showCheckbox={showCheckbox}
								isVisibleSoloDuo={this.state.isVisibleSoloDuo}
								UIType={this.state.whatsNeededData.UIType}
								height={this.state.height + 30}
							/>
						) : (
							""
						)}
					</Col>
				</Row>
				{/**
				 * Servicel level modal
				 */}
				{this.state.renderServiceLevel && (
					<ServiceLevelModal
						showServiceModal={this.state.showServiceModal}
						handleServiceModalClose={this.handleServiceModalClose}
					/>
				)}
				{this.state.showAlertModal && (
					<PickupExists
						showAlertModal={this.state.showAlertModal}
						resetBungii={this.resetBungiiData.bind(this)}
						handleCloseAlert={this.handleCloseAlert}
						pickupRequestID={pickupRequestID}
					/>
				)}
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(
	connect(
		({
			getEstimate: {
				pickupPoint,
				minDate,
				geofence,
				defaultGeofenceIndex,
				rerenderDropoff,
				pickupDate,
				isDuo,
				defaultPoint,
				pickupAdd,
				pickupAddObj,
				defaultAdd,
				loadUnloadTimeShow,
				defaultGeofence,
				pickupTime,
				tripType,
				isMuscleHelpRequired,
				dropoffAdd,
				dropoffPoint,
				timeZone,
				dropoffAddObj,
				estimate,
				loadTime,
				pickupRequestID,
				showCheckbox,
				driverHelpCarryFieldMessage,
				serviceLevel,
				serviceLevelValues,
				deliveryTime,
				distance,
				fixedPricingDistance,
				pickupAddressess,
				partnerUpchargeValue,
				editPickupValue,
				isBoxTruck,
			},
			user: { accessToken, isAuthenticated, quickAccessUser, quoteOnlyUser },
			common: { appSetting, activeTab, showLoader, formFilled, showPrice },
			deliveryDetails: { pickupContactName, pickupContactPhone },
		}) => ({
			appSetting,
			pickupPoint,
			minDate,
			geofence,
			defaultGeofenceIndex,
			rerenderDropoff,
			pickupDate,
			isDuo,
			defaultPoint,
			pickupAdd,
			pickupAddObj,
			defaultAdd,
			loadUnloadTimeShow,
			defaultGeofence,
			pickupTime,
			tripType,
			isMuscleHelpRequired,
			dropoffAdd,
			dropoffPoint,
			timeZone,
			dropoffAddObj,
			estimate,
			loadTime,
			accessToken,
			activeTab,
			pickupRequestID,
			showCheckbox,
			driverHelpCarryFieldMessage,
			isAuthenticated,
			showLoader,
			formFilled,
			serviceLevel,
			serviceLevelValues,
			quickAccessUser,
			showPrice,
			deliveryTime,
			distance,
			fixedPricingDistance,
			pickupAddressess,
			pickupContactName,
			pickupContactPhone,
			quoteOnlyUser,
			partnerUpchargeValue,
			editPickupValue,
			isBoxTruck,
		}),
		(dispatch) => ({
			setAppSetting: (appSetting) => dispatch(setAppSetting(appSetting)),
			setPickupPoint: (pickupPoint) => dispatch(setPickupPoint(pickupPoint)),
			setMinDate: (minDate) => dispatch(setMinDate(minDate)),
			setGeofence: (geofence) => dispatch(setGeofence(geofence)),
			setDefaultGeofenceIndex: (defaultGeofenceIndex) =>
				dispatch(setDefaultGeofenceIndex(defaultGeofenceIndex)),
			setPickupDate: (pickupDate) => dispatch(setPickupDate(pickupDate)),
			setIsDuo: (isDuo) => dispatch(setIsDuo(isDuo)),
			setDefaultPoint: (defaultPoint) =>
				dispatch(setDefaultPoint(defaultPoint)),
			setPickupAdd: (pickupAdd) => dispatch(setPickupAdd(pickupAdd)),
			setPickupAddObj: (pickupAddObj) =>
				dispatch(setPickupAddObj(pickupAddObj)),
			setDropoffAddObj: (dropoffAddObj) =>
				dispatch(setDropoffAddObj(dropoffAddObj)),
			setDefaultAdd: (defaultAdd) => dispatch(setDefaultAdd(defaultAdd)),
			setLoadUnloadTimeShow: (loadUnloadTimeShow) =>
				dispatch(setLoadUnloadTimeShow(loadUnloadTimeShow)),
			setDefaultGeofence: (defaultGeofence) =>
				dispatch(setDefaultGeofence(defaultGeofence)),
			setPckupTime: (pickupTime) => dispatch(setPckupTime(pickupTime)),
			setTripTypeValue: (tripType) => dispatch(setTripTypeValue(tripType)),
			setIsMuscleHelpRequired : (isRequired) => dispatch(setIsMuscleHelpRequired(isRequired)),
			setDropoffAdd: (dropoffAdd) => dispatch(setDropoffAdd(dropoffAdd)),
			setLoadTimeValue: (loadTime) => dispatch(setLoadTimeValue(loadTime)),
			setDropoffPoint: (dropoffPoint) =>
				dispatch(setDropoffPoint(dropoffPoint)),
			setTimeZone: (timeZone) => dispatch(setTimeZone(timeZone)),
			setEstimate: (estimate) => dispatch(setEstimate(estimate)),
			setActiveTab: (activeTab) => dispatch(setActiveTab(activeTab)),
			setPickupRequestID: (pickupRequestID) =>
				dispatch(setPickupRequestID(pickupRequestID)),
			setShowCheckbox: (showCheckbox) =>
				dispatch(setShowCheckbox(showCheckbox)),
			setDriverHelpCarryFieldMessage: (driverHelpCarryFieldMessage) =>
				dispatch(setDriverHelpCarryFieldMessage(driverHelpCarryFieldMessage)),
			setShowLoader: (showLoader) => dispatch(setShowLoader(showLoader)),
			setFormIsFilled: (formFilled) => dispatch(setFormIsFilled(formFilled)),
			setServiceLevel: (serviceLevel) =>
				dispatch(setServiceLevel(serviceLevel)),
			resetDataOnPageChange: (data) => dispatch(resetDataOnPageChange(data)),
			setDeliveryDetailsInitialState: (newValues) =>
				dispatch(setDeliveryDetailsInitialState(newValues)),
			setServiceLevelValues: (serviceLevelValues) =>
				dispatch(setServiceLevelValues(serviceLevelValues)),
			setShowPrice: (showPrice) => dispatch(setShowPrice(showPrice)),
			setDeliveryTime: (deliveryTime) =>
				dispatch(setDeliveryTime(deliveryTime)),
			setDistance: (distance) => dispatch(setDistance(distance)),
			setPickupAddressess: (pickupAddressess) =>
				dispatch(setPickupAddressess(pickupAddressess)),
			setPickupContactName: (pickupContactName) =>
				dispatch(setPickupContactName(pickupContactName)),
			setPickupContactPhone: (pickupContactPhone) =>
				dispatch(setPickupContactPhone(pickupContactPhone)),
			setPartnerUpchargeValue: (partnerUpchargeValue) =>
				dispatch(setPartnerUpchargeValue(partnerUpchargeValue)),
			setEditPickup: (editPickupValue) =>
			dispatch(setEditPickup(editPickupValue)),
			setIsBoxTruck: (isBoxTruck) =>
				dispatch(setIsBoxTruck(isBoxTruck)),
			setBackToQuote: (backToQuote) => 
				dispatch(setBackToQuote(backToQuote)),
		})
	)(QuoteOnly)
);
