import React from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import arrowDown from "../assets/images/icons/icon-angle-down.png";
import xicon from "../assets/images/icons/icon-x.png";
import DeliveryDetailsTable from "./DeliveryDetailsTable";

const SkuForm = ({
	formDetails,
	textChange,
	skuNumber,
	addSku,
	errors,
	quantityKeyDown,
	updateItemQuantity,
    handleRemoveClick,
	tableData,
	tableHeader

}) => {
	const { appSetting } = useSelector((state) => state.common);

	const { PartnerLocationSettings } = appSetting;

	//Columns for bestbuy portal which has SKU.
	const skuColumns = [
		{ title: 'SKU', value: "SKU" },
		{ title: "Item", value: "Name" },
		{ title: "Quantity", value: "Quantity",
		formatter: (data,i) => {
			return (
				<React.Fragment>
					<input
						type="text"
						autoComplete="off"
						id={"quantity_" + i}
						name={"quantity_" + i}
						className="quantity"
						onChange={(e) =>
							quantityKeyDown(e)
						}
						data-index={i}
						value={data.Quantity}
						onKeyDown={(e) =>
							quantityKeyDown(e)
						}
					/>
					<div className="counter">
						<img
							src={arrowDown}
							className="close-details decrement"
							data-field="decrement"
							onClick={(e) =>
								updateItemQuantity(e)
							}
							data-index={i}
							alt="Arrow Down"
						/>
						<img
							src={arrowDown}
							className="increment"
							data-field="increment"
							onClick={(e) =>
								updateItemQuantity(e)
							}
							data-index={i}
							alt="Arrow Up"
						/>
					</div>
				</React.Fragment>
			);
		},},
		{ title: "", value: "" ,
		formatter: (data,i) => {
			return (
				<React.Fragment >
					<div onClick={() =>handleRemoveClick(i)} className="delete">
						<img src={xicon} alt="Remove" />
					</div>
				</React.Fragment>
			);
		},},
	]

	return (
		<div>
			<Form.Label className="d-block">
				{PartnerLocationSettings.DeliveryDetailsFields[0].DisplayLabel}
				<span className="text-mandatory">*</span>
			</Form.Label>
			<InputGroup className="mb-5">
				<Form.Control
					maxLength="7"
					className={"col-9 col-sm-6 sku-text"}
					onChange={textChange}
					name="skuNumber"
					type="text"
					autoComplete="off"
					placeholder={
						PartnerLocationSettings.DeliveryDetailsFields[0].WatermarkLabel
					}
					value={skuNumber}
				/>
				<Button id="SKU" className="btn sku-btn" type="button" onClick={addSku}>
					ADD
				</Button>
				{errors.skunumber && (
					<div className="invalid-feedback d-block">{errors.skunumber}</div>
				)}
			</InputGroup>

			{formDetails.itemsToDeliver && formDetails.itemsToDeliver.length > 0 && (
				<div>
					<DeliveryDetailsTable
						tableHeader={skuColumns}
						tableData={tableData}
						tableClass={"sku-table"}
					/>
				</div>
			)}
		</div>
	);
};

export default SkuForm;
