import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Form as CalidationForm, Validation } from "calidation";
import InputMask from "react-input-mask";
import appConfig from "../shared/appConfig/appConfig";
import {
	handleGetReq,
	handlePostReq,
	handlePatchReq,
} from "../shared/services/AppendHeadersService";
import DropIn from "braintree-web-drop-in-react";
import toasterService from "../shared/services/ToasterService";
import globalConstants from "../shared/appConfig/globalConstants";
import languageConstants from "../shared/appConfig/languageConstants";
import { dateUtils } from "../shared/utilities/dateUtils";
import Password from "../shared/layouts/common/Password";
import AuthService from "../shared/services/AuthService";
import clearAppsettingData from "../shared/helpers/clearAppsettingData";
import xicon from "../assets/images/icons/icon-x.png";
import deleteicon from "../assets/images/icons/trash.svg";
import ReactGA from "react-ga";
import { validateSkuNumber } from "../shared/modules/Validations";
import arrowDown from "../assets/images/icons/icon-angle-down.png";
import TooltipOverlay from "./TooltipOverlay";
import PickupExists from "../shared/layouts/common/PickupExists";
import defaultGeofence from "../shared/helpers/defaultGeofence";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SelectBox from "../shared/modules/SelectBox";
import { validateNumber } from "../shared/utilities/inputUtils";
import "react-tagsinput/react-tagsinput.css";

/* Redux Import Start */
import { connect } from "react-redux";
import {
	setActiveTab,
	setShowLoader,
	setFormIsFilled,
	setKioskMode,
	setAppSetting,
	setCommonInitialState,
} from "../shared/redux/actions/common.actions";
import {
	setPickupRequestID,
	setPickupAdd,
	setDropoffAdd,
	resetDataOnPageChange,
} from "../shared/redux/actions/getEstimate.actions";
import {
	setPickupDetails,
	setLoadingUnloadingTime,
	setNoOfDrivers,
	setScheduledTimestamp,
	setLastFour,
	setPaymentMethodSelected,
	setStaticFields,
	setCustomFields,
	setCustomerName,
	setCustomerMobile,
	setPickUpNote,
	setSpecialInstructions,
	setItemsToDeliver,
	setOrderNumber,
	setExternalIdLabel,
	setPalletData,
	setPickupContactName,
	setPickupContactPhone,
	setDeliveryDetailsInitialState,
	setPickupToken,
	setMultiplePhs,
	setPalletErrors,
	setBackToQuote,
} from "../shared/redux/actions/deliveryDetails.actions";
import SelectItemsToDeliver from '../Components/MultiSelect';
import { removeIntersection } from '../shared/utilities/arrayUtils';
import ItemsToDeliver from '../Components/ItemsToDeliver';
import PalletForm from '../Components/PalletForm';
import SkuForm from '../Components/SkuForm';
import { withRouter } from 'react-router-dom';
import DeliveryDetailsTable from "../Components/DeliveryDetailsTable";

/* Redux Import End */

let saveDeliveryInformationFormConfig = {
	customerMobile: {
		isRequired: languageConstants.errors.requiredField.message,
		isNumber: languageConstants.errors.invalidField.message,
		isExactLength: {
			message: languageConstants.errors.invalidField.message,
			length: globalConstants.MaxAllowedDigitsInPhoneNumber,
		},
	},
	customerName: {
		isRequired: languageConstants.errors.requiredField.message,
	},
	pickUpNote: {
		isRequired: languageConstants.errors.requiredField.message,
	},
};

const saveDeliveryInformationTransformsConfig = {
	customerMobile: (value) => value.replace(/\D/g, ""),
};

const styles = {
	formControl: {
		minWidth: 220,
	},
};

class DeliveryDetails extends React.Component {
	constructor(props) {
		super(props);
		const existingData = this.getExistingData();

		const maxWeightField =
			this.props.appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]
				.MaxItemWeight;
		this.state = {
			showPaymentMethod:
				this.props.appSetting.PartnerLocationSettings
					.PaymentMethodSelectionRequired,
			paymentMethod: this.props.quickAccessUser
				? [{ PaymentCode: "CC", IsDefault: true }]
				: this.props.appSetting.PartnerLocationSettings.PaymentMethods,
			formDetails: {
				customerName: this.props.customerName,
				customerMobile: this.props.customerMobile,
				pickUpNote: this.props.pickUpNote,
				specialInstructions: this.props.specialInstructions,
				itemsToDeliver: this.props.itemsToDeliver,
				palletData: this.props.palletData,
			},
			nonce: "",
			open: false,
			clickedPay: false,
			clickedKioskMode: false,
			clickedtrackDeliveries: false,
			selectedPayMethod: "",
			...existingData,
			category: "",
			action: "",
			label: "",
			isBestbuy: true,
			emptySkuNumber: false,
			skuNumber: "",
			skuNumberExists: false,
			checkedItems : [],
			regexp: /^[0-9\b]+$/,
			isVisibleSoloDuo:
				this.props.appSetting.PartnerLocationSettings.EstimatePageFields[0]
					.IsVisible,
			disclaimerUnchecked: "",
			validPalletFields: false,
			errors: {
				password: "",
				skunumber: "",
				palletWeight: "",
				pallet: [],
				maxWeight: "",
				maxVolume: "",
			},
			whatsNeededData:
				this.props.appSetting.PartnerLocationSettings.EstimatePageFields[0],
			showAlertModal: false,
			disableScheduleBtn: false,
			maxItemWeight: maxWeightField
				? maxWeightField
				: globalConstants.SkuItemMaxWeight,
		};
		this.onPasswordChange = this.onPasswordChange.bind(this);
		this.palletTypeRef = React.createRef();
	}

	/**
	 * sendEvent()
	 * This function is used to send trigger event to GA
	 */
	sendEvent = async (val) => {
		await this.setState({
			category: `CTA ${val} Button`,
			action: "Click",
			label: val,
		});
		ReactGA.event(this.state);
	};

	/**
	 * getExistingData()
	 * This function is used to get the existing data
	 */
	getExistingData = () => {
		try {
			const {
				appSetting,
				paymentMethodSelected,
				pickUpNote,
				customerName,
				customerMobile,
				specialInstructions,
				itemsToDeliver,
				setAppSetting,
				pickupContactName,
				pickupContactPhone,
				multiplePhoneNumbers,
			} = this.props;
			let formDetails = {},
				existingData = {},
				updateStaticFields = [
					...appSetting.PartnerLocationSettings.StaticFields,
				],
				updateCustomFields = [
					...appSetting.PartnerLocationSettings.CustomFields,
				];

			if (
				appSetting &&
				appSetting.PartnerLocationSettings &&
				appSetting.PartnerLocationSettings.StaticFields
			) {
				appSetting.PartnerLocationSettings.StaticFields.forEach(function (
					value,
					i
				) {
					formDetails[value.FieldRef] = value.FieldValue
						? value.FieldValue
						: value.DefaultValue;
					let pickContactName =
						pickupContactName && JSON.parse(pickupContactName);
					let pickContactPhone =
						pickupContactPhone && JSON.parse(pickupContactPhone);
					if (pickContactName && pickContactPhone) {
						/*Check if value is changed or if its the same and update the state*/
						if (updateStaticFields[i].MappingDBFieldName === pickContactName.FieldName) {
							formDetails[value.FieldRef] =
								value.FieldValue === pickContactName.FieldValue
									? value.FieldValue
									: pickContactName.FieldValue;
							updateStaticFields[i].FieldValue =
								value.FieldValue === pickContactName.FieldValue
									? value.FieldValue
									: pickContactName.FieldValue;
						}
						if (
							updateStaticFields[i].MappingDBFieldName === pickContactPhone.FieldName
						) {
							formDetails[value.FieldRef] =
								value.FieldValue === pickContactPhone.FieldValue
									? value.FieldValue
									: pickContactPhone.FieldValue;
							updateStaticFields[i].FieldValue =
								value.FieldValue === pickContactPhone.FieldValue
									? value.FieldValue
									: pickContactPhone.FieldValue;
						}
					} else {
						if (value.DefaultValue) {
							if (updateStaticFields[i].FieldRef === value.FieldRef) {
								updateStaticFields[i].FieldValue = value.FieldValue
									? value.FieldValue
									: value.DefaultValue;
							}
						}
					}
				});
			}
			if (
				appSetting &&
				appSetting.PartnerLocationSettings &&
				appSetting.PartnerLocationSettings.CustomFields
			) {
				appSetting.PartnerLocationSettings.CustomFields.forEach(function (
					value,
					i
				) {
					formDetails[value.FieldRef] = value.FieldValue
						? value.FieldValue
						: value.DefaultValue;
					if (value.DefaultValue) {
						if (updateCustomFields[i].FieldRef === value.FieldRef) {
							updateCustomFields[i].FieldValue = value.FieldValue
								? value.FieldValue
								: value.DefaultValue;
						}
					}
				});
			}
			let phs = {};
			multiplePhoneNumbers.map((item, i) => {
				phs[`customerMobile${i}`] = item ? item : "";
				return item;
			});
			setAppSetting(appSetting);
			existingData = {
				...existingData,
				formDetails: {
					...formDetails,
					pickUpNote: pickUpNote
						? pickUpNote
						: this.props.appSetting.PartnerLocationSettings
							.DeliveryDetailsFields[0].UIType === 1
							? ""
							: "empty",
					customerName: customerName
						? customerName
						: appSetting.PartnerLocationSettings.DeliveryBusinessModel === 2
							? appSetting.PartnerLocationSettings.BusinessPartnerName
							: "",
					customerMobile: customerMobile
						? customerMobile
						: appSetting.PartnerLocationSettings.DeliveryBusinessModel === 2
							? appSetting.PartnerLocationSettings.BusinessPartnerLocationPhone
							: "",
					specialInstructions: specialInstructions ? specialInstructions : "",
					itemsToDeliver:
						itemsToDeliver.length > 0
							? itemsToDeliver
							: [],
					palletResults:
						(this.props.appSetting.PartnerLocationSettings
							.DeliveryDetailsFields[0].UIType === 3 || this.props.appSetting.PartnerLocationSettings
							.DeliveryDetailsFields[0].UIType === 5)
							? this.checkPalletData()
							: [],
					...phs,
				},
				paymentMethodSelected: paymentMethodSelected,
			};
			return existingData;
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * checkPalletData()
	 * This function is used to update the default pallet data based on the user tripType selection.
	 */
	checkPalletData = () => {
		const { tripType, palletData, setPalletData, appSetting ,isServiceLevelUpdated} = this.props;

		// Check if service level is updated in getestimate page.
		// If it is updated, will update product desciption Name to updated service level.
		// This is only for XL Tile portal where in we prepopulate product description.
		let data = appSetting.PartnerLocationSettings.DeliveryDetailsFields[0].Fields.map((data) => {
			if (data.DefaultValue === "servicelevel" && isServiceLevelUpdated) {
				return palletData.map((item) => { return { ...item, Name: this.props.serviceLevel.ServiceName } })
			} else return null;
		})

		if (palletData && palletData.length > 0) {
           /*Below conditions are used to render items to deliver fields based on selected tripType*/
			if (tripType > palletData.length) {
				let addPallets = tripType - palletData.length;
				for (let i = 1; i <= addPallets; i++) {
					let dimensionsObj = {};
					appSetting.PartnerLocationSettings.DeliveryDetailsFields[0].Fields.map(
						(data) => dimensionsObj[data.FieldName] = ""
					)
					dimensionsObj["ID"] = ""
					palletData.push(dimensionsObj);
				}
				setPalletData(palletData);
				return palletData;

			} else if (tripType < palletData.length) {
				let removePallets = palletData.length - tripType;
				for (let i = 1; i <= removePallets; i++) {
					palletData.pop()
				}
				setPalletData(palletData);
				return palletData;
			} else if (data[0]?.length > 0) {
				setPalletData(data[0]);
				return data[0];
			} else {
				return palletData;
			}
			
		} else {
			let palletsData = [];
			for (let i = 1; i <= tripType; i++) {
				let dimensionsObj = {};
				appSetting.PartnerLocationSettings.DeliveryDetailsFields[0].Fields.map(
					(data) => {
						if (data.DefaultValue === "servicelevel") return dimensionsObj[data.FieldName] = this.props.serviceLevel.ServiceName
						else return dimensionsObj[data.FieldName] = ""
					}
				)
				dimensionsObj["ID"] = ""
				palletsData.push(dimensionsObj);

			}
			return palletsData;
		}
	};

	/**
	 * setEmptyPalletErrors()
	 * This function is used to set the number of enteries in the error array based on the user tripType selection.
	 */
	setEmptyPalletErrors = () => {
		try {
			const { tripType } = this.props;

			let palletErr = { ...this.state.errors };

			for(let i=1; i<=tripType; i++){
				palletErr.pallet.push({ palletError: "" })
			}

			this.setState({
				errors: palletErr,
			});
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * addCustomerMobile()
	 * This function is called when user adds new customer mobile field
	 */
	addCustomerMobile = () => {
		const { multiplePhoneNumbers, setMultiplePhs } = this.props;
		const { formDetails } = this.state;
		const tmp = JSON.parse(JSON.stringify(multiplePhoneNumbers));
		if (tmp.length === 3) return;
		tmp.push("");
		const formTmp = JSON.parse(JSON.stringify(formDetails));
		formTmp[`customerMobile${tmp.length - 1}`] = "";
		this.setState({ formDetails: formTmp }, () => setMultiplePhs(tmp));
	};

	/**
	 * customerMobileChangeHandler()
	 * This function is called when user enters number in the extra customer mobile fields
	 */
	customerMobileChangeHandler = (i, e) => {
		const { multiplePhoneNumbers, setMultiplePhs } = this.props;
		const { formDetails } = this.state;
		const tmp = JSON.parse(JSON.stringify(multiplePhoneNumbers));
		const formTmp = JSON.parse(JSON.stringify(formDetails));
		tmp[i] = e.target.value; // Update redux value
		formTmp[e.target.name] = e.target.value; // Update state value
		this.setState({ formDetails: formTmp }, () => setMultiplePhs(tmp));
	};

	/**
	 * removeCustomerMobile()
	 * This function is called when user deletes newly added customer mobile fields
	 */
	removeCustomerMobile = (i) => {
		const { multiplePhoneNumbers, setMultiplePhs } = this.props;
		const tmp = JSON.parse(JSON.stringify(multiplePhoneNumbers));
		tmp.splice(i, 1);

		let formTmp = JSON.parse(JSON.stringify(this.state.formDetails));
		for (let i = 0; i < 3; i++) {
			delete formTmp[`customerMobile${i}`]; // Delete  all existing customerMobile properties in formdetails
		}
		let phs = {};
		// Rebuild customer mobile properties in formdetails
		tmp.map((item, i) => {
			phs[`customerMobile${i}`] = item ? item : "";
			return item;
		});
		formTmp = { ...formTmp, ...phs }; // Update formdetails
		this.setState({ formDetails: formTmp }, () => setMultiplePhs(tmp));
	};

	/**
	 * validateCustomerMobile()
	 * This function is used for mobile number length validation in extra customer mobile fields
	 */
	validateCustomerMobile = (val) => {
		const length = globalConstants.MaxAllowedDigitsInPhoneNumber;
		val = val.replace(/\D/g, ""); // Get unmasked phone value
		if (val.length !== length && val.length !== 0) return true; // Error present
		return false; // No error
	};

	/**
	 * componentDidMount()
	 * This function will be called only after the first render.
	 */
	componentDidMount = async () => {
		try {
			const {
				accessToken,
				pickupRequestID,
				setPickupDetails,
				setLoadingUnloadingTime,
				setPaymentMethodSelected,
				setShowLoader,
				orderRef,
				tripType,
				setBackToQuote,
				isBoxTruck,
			} = this.props;
			if (pickupRequestID || orderRef) {
				setShowLoader(true);
				let estimateResponse = await handleGetReq(
					appConfig.urls.pickupDetails,
					(tripType > 2 && !isBoxTruck) ? {orderref: orderRef} : { pickuprequestid: pickupRequestID },
					accessToken
				);
				/**
				 * Set redux state
				 */
				setPickupDetails(estimateResponse.data.PickupDetails);
				setLoadingUnloadingTime(
					estimateResponse.data.PickupDetails.LoadingUnloadingTime
				);
				this.state.paymentMethod.map((val, index) => {
					if (val.IsDefault === true) {
						setPaymentMethodSelected(
							this.state.paymentMethodSelected
								? this.state.paymentMethodSelected
								: this.state.paymentMethod[index].PaymentCode
						);
					}
					return true;
				});

				await this.setState({
					paymentMethodSelected: this.state.paymentMethodSelected
						? this.state.paymentMethodSelected
						: this.state.paymentMethod[0].PaymentCode,
					serviceName: estimateResponse.data.PickupDetails.PickupServiceLevel
						? estimateResponse.data.PickupDetails.PickupServiceLevel.ServiceName
						: "",
					serviceDescription: estimateResponse.data.PickupDetails
						.PickupServiceLevel
						? estimateResponse.data.PickupDetails.PickupServiceLevel
							.PartnerServiceNameDescription
						: "",
				});

				/*Setting error arry on page load*/
				await this.setEmptyPalletErrors();

				await this.getPickupDetails();
				if (this.state.paymentMethodSelected === "CC") {
					await this.showCard();
				}
				setBackToQuote(false)
				setShowLoader(false);
			}
			await this.showDriverHelpCarryField();
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * updatedisclaimerFieldMessage()
	 * This function is used to update the disclaimer messages.
	 */
	updatedisclaimerFieldMessage = async () => {
		try {
			const { appSetting, defaultGeofenceIndex, loadingUnloadingTime } =
				this.props;
			let pickupCostPerMinute =
				appSetting.GeofenceSettings[defaultGeofenceIndex].PickupCostPerMinute;
			let pickupCostPerMile =
				appSetting.GeofenceSettings[defaultGeofenceIndex].PickupCostPerMile;
			let loadingUnloadingTimeVal = Math.floor(
				loadingUnloadingTime / (1000 * 60)
			); /*convert milliseconds to minutes*/
			let loadingUnloadingCost = pickupCostPerMinute * loadingUnloadingTimeVal;
			let myString = this.state.disclaimerField,
				replaceArray = [
					"pickupCostPerMinute",
					"pickupCostPerMile",
					"loadingUnloadingTime",
					"loadingUnloadingCost",
				],
				replaceWith = [
					pickupCostPerMinute,
					pickupCostPerMile,
					loadingUnloadingTimeVal,
					loadingUnloadingCost,
				];
			let that = this;
			replaceArray.forEach(function (value, i) {
				myString = myString.replace(
					new RegExp("{{" + replaceArray[i] + "}}"),
					replaceWith[i]
				);
				that.setState({
					disclaimerField: myString,
				});
			});
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * getPickupDetails()
	 * This function is used to fetch the pickup details.
	 */
	getPickupDetails = async () => {
		try {
			const { pickupDetails, setNoOfDrivers, setScheduledTimestamp } =
				this.props;
			const {
				ScheduledTimestamp: scheduledTimestamp,
				TimezoneIdentifier: timezoneIdentifier,
				TimezoneDisplayName: timezoneDisplayName,
			} = pickupDetails;
			let scheduledDatetime = dateUtils.convertTimezone(
				scheduledTimestamp,
				timezoneIdentifier,
				`MMM DD [at] h:mm A [${timezoneDisplayName}]`
			);
			/**
			 * Set redux state
			 */
			setNoOfDrivers(pickupDetails.NoOfDrivers);
			setScheduledTimestamp(scheduledDatetime);
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * textChange()
	 * This function is called when you add/remove any content from the text boxes "which are not pulled from the database".
	 */
	textChange = async (e) => {
		try {
			const {
				setCustomerName,
				setCustomerMobile,
				setPickUpNote,
				setSpecialInstructions,
			} = this.props;
			let updateformDetails = { ...this.state.formDetails };

			updateformDetails[e.target.name] = e.target.value;

			if (e.target.name === "skuNumber") {
				var skuNumberVal = "";
				if (this.state.regexp.test(e.target.value) === true) {
					skuNumberVal = e.target.value;
					this.setState({
						errors: {
							...this.state.errors,
							skunumber: "",
						},
					});
				} else {
					e.preventDefault();
				}
			}

			switch (e.target.name) {
				case "pickUpNote":
					setPickUpNote(e.target.value);
					break;
				case "specialInstructions":
					setSpecialInstructions(e.target.value);
					break;
				case "customerName":
					setCustomerName(e.target.value);
					break;
				case "customerMobile":
					setCustomerMobile(e.target.value);
					break;
				case "skuNumber":
					this.setState({ skuNumber: skuNumberVal });
					break;
				default:
					setPickUpNote(e.target.value);
					break;
			}
			await this.setState({
				formDetails: updateformDetails,
			});
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * handleFieldChange()
	 * This function is used to handle Custom and Static text boxes which are pulled from the database.
	 */
	handleFieldChange = async (
		e,
		index,
		fieldRef,
		ControlType,
		fields,
		type,
		setError
	) => {
		try {
			const {
				appSetting,
				setAppSetting,
				setOrderNumber,
				setExternalIdLabel,
				setPickupContactName,
				pickupContactName,
				setPickupContactPhone,
				pickupContactPhone,
				pickupAddressess,
			} = this.props;
			if (type === "Static") {
				if (pickupAddressess && pickupAddressess.length > 0) {
					let pickContactName =
						pickupContactName && JSON.parse(pickupContactName);
					let pickContactPhone =
						pickupContactPhone && JSON.parse(pickupContactPhone);
					if (pickContactName && pickContactPhone) {
						/*Update the value of the pickup dropdown DefaultStaticFields based on the value updated by the user.*/
						let targetName = e.currentTarget.getAttribute("data-field");
						if (targetName === "pickup_contact_name") {
							pickContactName.FieldValue = e.target.value;
							setPickupContactName(JSON.stringify(pickContactName));
						} else if (targetName === "pickup_contact_phone") {
							pickContactPhone.FieldValue = e.target.value;
							setPickupContactPhone(JSON.stringify(pickContactPhone));
						}
					}
				}

				if (ControlType === "DropDown") {
					setError({
						[fieldRef]: e.target.value
							? null
							: languageConstants.errors.requiredField.message,
					});
					fields[fieldRef] = e.target.value;
				}

				let updateStaticFields = [
					...appSetting.PartnerLocationSettings.StaticFields,
				];
				for (let i = 0; i < updateStaticFields.length; i++) {
					if (updateStaticFields[i].FieldRef === fieldRef) {
						updateStaticFields[i].FieldValue = e.target.value;
						if (updateStaticFields[i].DisplayOnSuccessPage === true) {
							setOrderNumber(e.target.value);
							setExternalIdLabel(updateStaticFields[i].DisplayName)
						}
						break;
					}
				}
				await this.setState({
					formDetails: {
						...this.state.formDetails,
						[e.target.name]: e.target.value,
					},
				});
				setAppSetting(appSetting);
			} else if (type === "Custom") {
				if (ControlType === "DropDown") {
					setError({
						[fieldRef]: e.target.value
							? null
							: languageConstants.errors.requiredField.message,
					});
					fields[fieldRef] = e.target.value;
				}
				let updateCustomFields = [
					...appSetting.PartnerLocationSettings.CustomFields,
				];
				for (let i = 0; i < updateCustomFields.length; i++) {
					if (updateCustomFields[i].FieldRef === fieldRef) {
						updateCustomFields[i].FieldValue = e.target.value;
						break;
					}
				}
				await this.setState({
					formDetails: {
						...this.state.formDetails,
						[e.target.name]: e.target.value,
					},
				});
				setAppSetting(appSetting);
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * showCard()
	 * This function is used to show the payment card.
	 */
	showCard = async () => {
		const { accessToken, setShowLoader } = this.props;
		setShowLoader(true);
		/*getPaymentToken*/
		try {
			const getPaymentToken = await handleGetReq(
				appConfig.urls.getPaymentToken,
				"",
				accessToken
			);
			this.setState({
				clientToken: getPaymentToken.data.ClientToken,
			});
		} catch (e) {
			console.log(e);
		}
		setShowLoader(false);
	};

	/**
	 * onRadioButtonChange()
	 * This Function is called when the user clicks on the payment option radio buttons.
	 */
	onRadioButtonChange = async (e) => {
		e.persist();
		const { setShowLoader } = this.props;

		try {
			setShowLoader(true);

			const { setPaymentMethodSelected, kioskMode } = this.props;
			if (e.target.value !== "CC") {
				if (kioskMode === false) {
					await this.setState({
						open: true,
						selectedPayMethod: e.target.value,
						clickedPay: true,
					});
				} else {
					await this.setState({
						selectedPayMethod: e.target.value,
					});
					/**
					 * Set redux states
					 */
					setPaymentMethodSelected(e.target.value);
					this.paymentMode();
				}
			} else {
				await this.setState({
					selectedPayMethod: e.target.value,
				});
				/**
				 * Set redux states
				 */
				setPaymentMethodSelected(e.target.value);
				this.paymentMode();
			}
			setShowLoader(false);
		} catch (e) {
			console.log(e);
		}
	};

	/**
	 * paymentMode()
	 * This function is used to change the paymentMode based on the user selection.
	 */
	paymentMode = async (e) => {
		const { setShowLoader } = this.props;
		setShowLoader(true);
		toasterService.hideCustomToasty();
		try {
			const { accessToken } = this.props;
			if (this.state.selectedPayMethod === "CC") {
				/*getPaymentToken*/
				try {
					if (
						this.state.clientToken === null ||
						this.state.clientToken === ""
					) {
						const getPaymentToken = await handleGetReq(
							appConfig.urls.getPaymentToken,
							"",
							accessToken
						);
						this.setState({
							clientToken: getPaymentToken.data.ClientToken,
						});
					}
				} catch (e) {
					console.log(e);
				}
				await this.showCard();
			} else {
				this.showDriverHelpCarryField();
			}

			setShowLoader(false);
		} catch (e) {
			console.log(e);
		}
	};

	/**
	 * addCard()
	 * This function is called when you enter the card details and then click on "Schedule Bungii".
	 */
	addCard = async () => {
		const { setLastFour, paymentMethodSelected } = this.props;
		if (paymentMethodSelected === "CC") {
			toasterService.hideCustomToasty();
			try {
				/*Send the nonce to your server*/
				let cardResponse = await this.instance.requestPaymentMethod();
				const nonce = cardResponse.nonce;
				const lastFour = cardResponse.details.lastFour;
				/**
				 * Set redux states
				 */
				setLastFour(lastFour);

				await this.setState({
					nonce,
				});
				return true;
			} catch (e) {
				console.log(e);
				return false;
			}
		} else {
			return true;
		}
	};

	/**
	 * validatePalletFields()
	 * This function is used to validate pallet fields on click of "Schedule Bungii" button.
	 */
	validatePalletFields = () => {
		try {
			if (
				this.props.appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]
					.UIType === 3
			) {
				if (this.state.formDetails.palletResults.length > 0) {
					let isValidPallet = false;
					this.state.formDetails.palletResults.map((val, index) => {
						let fieldErr = [...this.state.errors.pallet];
						let maxAllowedValue = this.props.appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]["Fields"][1]["MaxAllowedValue"];
						let minAllowedValue = this.props.appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]["Fields"][1]["MinAllowedValue"];
						if (
							val["Name"] === "" ||
							val["Dimensions"] === "" ||
							val["Weight"] === "" ||
							val["Depth"] === "" ||
							val["Width"] === "" ||
							val["Height"] === "" 
						) {
							if (val["Name"] === "") {
								document
									.getElementById(`Name_${index}`)
									.classList.add("invalid");
							}
							if (val["Dimensions"] === "") {
								document
									.getElementById(`Dimensions_${index}`)
									.classList.add("invalid");
							}
							if (val["Weight"] === "") {
								document
									.getElementById(`Weight_${index}`)
									.classList.add("invalid");
							}
							if (val["Depth"] === "") {
								document
									.getElementById(`Depth_${index}`)
									.classList.add("invalid");
							}
							if (val["Width"] === "") {
								document
									.getElementById(`Width_${index}`)
									.classList.add("invalid");
							}
							if (val["Height"] === "") {
								document
									.getElementById(`Height_${index}`)
									.classList.add("invalid");
							}
							fieldErr[index]["palletError"] =
								languageConstants.errors.pallets.emptyPallet;
							this.setState({
								errors: { ...this.state.errors, pallet: fieldErr },
							});
							isValidPallet = true;
						} else if (fieldErr[index]?.palletError !== "") {
							this.setState({
								errors: { ...this.state.errors, pallet: fieldErr },
							});
							isValidPallet = true;
						} else if (this.props.palletErrors[index]?.palletError !== "") {
							if (val["Depth"] < minAllowedValue || val["Depth"] > maxAllowedValue) {
								document
									.getElementById(`Depth_${index}`)
									.classList.add("invalid");
							}
							if (val["Width"] < minAllowedValue || val["Width"] > maxAllowedValue) {
								document
									.getElementById(`Width_${index}`)
									.classList.add("invalid");
							}
							fieldErr[index]["palletError"] = this.props.palletErrors[index]?.palletError;
							this.setState({
								errors: { ...this.state.errors, pallet: fieldErr },
							});
							isValidPallet = true;
						} else {
							fieldErr[index]["palletError"] = "";
							this.setState({
								errors: { ...this.state.errors, pallet: fieldErr },
							});
						}
						return true;
					});
					if (isValidPallet) {
						this.setState({
							validPalletFields: false,
							disclaimerUnchecked: true,
						});
					} else {
						this.setState({
							disclaimerUnchecked: false,
							validPalletFields: true,
						});
					}
				} else {
					this.setState({
						disclaimerUnchecked: false,
						validPalletFields: true,
					});
				}
			} else {
				this.setState({
					validPalletFields: true,
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * validateXLTileFields()
	 * This function is used to validate XL Tile pallet fields on click of "Schedule Bungii" button.
	 */
	validateXLTileFields = () => {
		try {
			let maxAllowedValue = this.props.appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]?.Fields[1]["MaxAllowedValue"];
			let minAllowedValue = this.props.appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]?.Fields[1]["MinAllowedValue"];
			let MaxValidationText = this.props.appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]?.Fields[1]["MaxValidationText"];
			let MinValidationText = this.props.appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]?.Fields[1]["MinValidationText"];
			if (this.state.formDetails.palletResults.length > 0) {
				let isValidPallet = false;
				this.state.formDetails.palletResults.map((val, index) => {
					let fieldErr = [...this.state.errors.pallet];
					if (
						val["Name"] === "" ||
						val["noOfSlabs"] === ""
					) {
						if (val["Name"] === "") {
							document
								.getElementById(`Name_${index}`)
								.classList.add("invalid");
						}
						if (val["noOfSlabs"] === "") {
							document
								.getElementById(`noOfSlabs_${index}`)
								.classList.add("invalid");
						}
						fieldErr[index]["palletError"] =
							languageConstants.errors.pallets.emptyXlTilePallet;
						this.setState({
							errors: { ...this.state.errors, pallet: fieldErr },
						});
						isValidPallet = true;
					} else if (val["noOfSlabs"] > maxAllowedValue) {
                        document
                            .getElementById(`noOfSlabs_${index}`)
                            .classList.add("invalid");
                        fieldErr[index]["palletError"] = MaxValidationText;
                        this.setState({
                            errors: { ...this.state.errors, pallet: fieldErr },
                        });
                        isValidPallet = true;
                    } else if (val["noOfSlabs"] < minAllowedValue) {
                        document
                            .getElementById(`noOfSlabs_${index}`)
                            .classList.add("invalid");
                        fieldErr[index]["palletError"] = MinValidationText;
                        this.setState({
                            errors: { ...this.state.errors, pallet: fieldErr },
                        });
                        isValidPallet = true;
                    } else {
						fieldErr[index]["palletError"] = "";
						this.setState({
							errors: { ...this.state.errors, pallet: fieldErr },
						});
					}
					return true;
				});
				if (isValidPallet) {
					this.setState({
						validPalletFields: false,
						disclaimerUnchecked: true,
					});
				} else {
					this.setState({
						disclaimerUnchecked: false,
						validPalletFields: true,
					});
				}
			} else {
				this.setState({
					disclaimerUnchecked: false,
					validPalletFields: true,
				});
			}
		} catch (err) {
			console.log(err);
		}
	};
	/**
	 * submitDetails()
	 * This function is called on click of "Schedule Bungii" button.
	 */
	submitDetails = async ({ fields, errors, isValid }) => {
		toasterService.hideCustomToasty();

		try {
			const { setShowLoader, appSetting } = this.props;
			const itemsToDeliverField = appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]
			const cartItems = this.state.formDetails.itemsToDeliver;
			let totalItemsWeight = 0, totalItemsVolume = 0.0;
			let isWeightExceedingMaxLimit, isVolumeExceedingMaxLimit;
			if(itemsToDeliverField.UIType === 4){
				cartItems.forEach(item => {
					totalItemsWeight += Number(item.Weight?.split(" ")[0]) * Number(item.Quantity)
					totalItemsVolume += item.Volume * Number(item.Quantity)
				})
				totalItemsVolume = parseFloat(totalItemsVolume).toFixed(2); 
				isWeightExceedingMaxLimit = itemsToDeliverField.MaxItemWeight && (itemsToDeliverField.MaxItemWeight < totalItemsWeight)
				isVolumeExceedingMaxLimit = itemsToDeliverField.MaxItemVolume && (itemsToDeliverField.MaxItemVolume < totalItemsVolume)
			}

			// UIType !== 3 non pallet items validation
			if (
				(appSetting.PartnerLocationSettings.DeliveryDetailsFields[0].UIType !==3 
					&& appSetting.PartnerLocationSettings.DeliveryDetailsFields[0].UIType !== 5) &&
				this.state.formDetails.itemsToDeliver.length <= 0 &&
				this.state.formDetails.pickUpNote === "empty"
			) {
				this.setState({
					errors: {
						...this.state.errors,
						skunumber: languageConstants.errors.skuItemsToDeliver.emptyField,
					},
					disclaimerUnchecked: true,
				});
			}else if(
				itemsToDeliverField.UIType === 4 && (
					isWeightExceedingMaxLimit || isVolumeExceedingMaxLimit
				)
			){
				if(itemsToDeliverField.MaxItemWeight < totalItemsWeight ){
					this.setState({
						errors: {
							...this.state.errors,
							maxWeight: languageConstants.errors.skuItemsToDeliver.maxItemWeight2,
						},
						disclaimerUnchecked: false,
					});
				}
				else if(itemsToDeliverField.MaxItemVolume && (itemsToDeliverField.MaxItemVolume < totalItemsVolume)){
					this.setState({
						errors: {
							...this.state.errors,
							maxVolume: languageConstants.errors.skuItemsToDeliver.maxItemVolume,
						},
						disclaimerUnchecked: false,
					});
				}
			}
			else {
				if (appSetting.PartnerLocationSettings.DeliveryDetailsFields[0].UIType === 5) {
					await this.validateXLTileFields();
				} else await this.validatePalletFields();
				if (!this.state.validPalletFields) {
					this.setState({ disclaimerUnchecked: true });
				} else if (!isValid) {
					if (
						this.state.showCheckbox &&
						document.getElementById("DisclaimerField").checked === false
					) {
						this.setState({ disclaimerUnchecked: true });
					}
				} else if (!this.state.showCheckbox) {
					await setShowLoader(true);
					await this.performUpdateDeliveryInformationAPIRequest(isValid);
					await setShowLoader(false);
				} else if (
					this.state.showCheckbox &&
					document.getElementById("DisclaimerField").checked === true
				) {
					await setShowLoader(true);
					await this.performUpdateDeliveryInformationAPIRequest(isValid);
					await setShowLoader(false);
				} else {
					if (this.state.showCheckbox) {
						this.setState({ disclaimerUnchecked: true });
					}
				}
			}
		} catch (e) {
			console.log(e);
		}
	};

	/**
	 * performUpdateDeliveryInformationAPIRequest()
	 * This function is used to fire the API request.
	 */
	performUpdateDeliveryInformationAPIRequest = async (isValid) => {
		const {
			pickupRequestID,
			accessToken,
			setActiveTab,
			paymentMethodSelected,
			setShowLoader,
			setFormIsFilled,
			appSetting,
			setPickUpNote,
			setItemsToDeliver,
			setPickupToken,
			multiplePhoneNumbers,
			tripType,
			orderRef,
			isBoxTruck,
		} = this.props;
		toasterService.hideCustomToasty();
		const { AddMultipleCustomerPhoneNumber } =
			appSetting.PartnerLocationSettings;

		if (!(await this.addCard())) {
			return false;
		}

		let staticFieldsVal = appSetting.PartnerLocationSettings.StaticFields.map(
			(field) => {
				let fieldValue = field.FieldValue || "";
				if (field.FieldDataType === globalConstants.FieldTypes.Phone) {
					fieldValue = fieldValue.replace(/\D/g, "");
				}
				return { FieldRef: field.FieldRef, FieldValue: fieldValue };
			}
		);

		let customFieldsVal = appSetting.PartnerLocationSettings.CustomFields.map(
			(field) => {
				return { FieldRef: field.FieldRef, FieldValue: field.FieldValue || "" };
			}
		);
		let body = {
			CustomerName: this.state.formDetails.customerName,
			CustomerMobile: AddMultipleCustomerPhoneNumber
				? [
					this.state.formDetails.customerMobile.replace(/\D/g, ""),
					...multiplePhoneNumbers
						.map((item) => item.replace(/\D/g, ""))
						.filter((item) => item.length > 0),
				]
				: [this.state.formDetails.customerMobile.replace(/\D/g, "")],
			PickupNote:
				this.state.formDetails.pickUpNote === "empty"
					? null
					: this.state.formDetails.pickUpNote,
			SpecialInstructions: this.state.formDetails.specialInstructions,
			StaticFields: staticFieldsVal,
			CustomFields: customFieldsVal,
			PaymentOption: paymentMethodSelected,
			PaymentMethodNonce: this.state.nonce ? this.state.nonce : null,
			ItemsToDeliver:
				(this.props.appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]
					.UIType === 3 || this.props.appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]
					.UIType === 5)
					? this.state.formDetails.palletResults
					: this.state.formDetails.itemsToDeliver.length > 0
						? this.state.formDetails.itemsToDeliver
						: [],
		};

		(tripType > 2 && !isBoxTruck) ? body.OrderRef = orderRef : body.PickupRequestID = pickupRequestID;

		setShowLoader(true);
		this.setState({
			disableScheduleBtn: true,
		});
		let deliveryInformation = await handlePatchReq(
			appConfig.urls.deliveryInformation,
			body,
			accessToken
		);
		const paymentFailureCodes = [
			languageConstants.errors.paymentFailure.code,
			languageConstants.errors.paymentPreAuthFailure1.code,
			languageConstants.errors.paymentPreAuthFailure2.code,
		];
		if (
			deliveryInformation !== undefined &&
			deliveryInformation.data.Error === null
		) {
			let bodyPickupConfirm = (tripType > 2 && !isBoxTruck) ? {
				OrderRef: orderRef,
			} : { PickupRequestID: pickupRequestID };

			let pickupConfirmResponse = await handlePostReq(
				appConfig.urls.confirmpickup,
				bodyPickupConfirm,
				accessToken
			);
			if (
				pickupConfirmResponse !== undefined &&
				pickupConfirmResponse.data.Error === null
			) {
				/**
				 * Clear appSetting data
				 */
				clearAppsettingData();
				await this.sendEvent("schedule-bungii");
				setPickUpNote("");
				setItemsToDeliver([]);
				setCustomerName(
					appSetting.PartnerLocationSettings.DeliveryBusinessModel === 2
						? appSetting.PartnerLocationSettings.BusinessPartnerName
						: ""
				);
				setCustomerMobile(
					appSetting.PartnerLocationSettings.DeliveryBusinessModel === 2
						? appSetting.PartnerLocationSettings.BusinessPartnerLocationPhone
						: ""
				);
				setSpecialInstructions("");
				setPickupToken(pickupConfirmResponse.data.PickupToken);
				setFormIsFilled(false);
				setActiveTab("done");
				setShowLoader(false);
				window.location.href = "/success";
			} else {
				toasterService.showCustomToasty(
					pickupConfirmResponse.data.Error.Message,
					"error"
				);
				if (
					paymentFailureCodes.includes(pickupConfirmResponse.data.Error.Code)
				) {
					this.setState({
						nonce: "",
						disableScheduleBtn: false,
					});
				}
			}
		} else if (deliveryInformation.data.Error.Code === 20042) {
			this.setState({
				showAlertModal: true,
				disableScheduleBtn: false,
			});
		} else if (deliveryInformation.data.Error.Code === 20009) {
			setShowLoader(false);
			toasterService.showCustomToasty(
				languageConstants.errors.invalidUser.message,
				"error"
			);
			this.setState({
				disableScheduleBtn: false,
			});
			setTimeout(function () {
				window.location.replace(`/`);
			}, 2000);
		} else {
			let errorMessage = deliveryInformation.data.Error.Message;
			if (
				deliveryInformation.data.Error.Code ===
				languageConstants.errors.itemsToDeliverMaxLengthExceeded.code
			) {
				errorMessage =
					languageConstants.errors.itemsToDeliverMaxLengthExceeded.message;
			} else if (
				paymentFailureCodes.includes(deliveryInformation.data.Error.Code)
			) {
				this.setState({
					nonce: "",
					disableScheduleBtn: false,
				});
			}
			toasterService.showCustomToasty(errorMessage, "error");
		}
		setShowLoader(false);
	};

	/**
	 * editBungii()
	 * This function is called on click of "Edit" and "Back to Quote" button.
	 */
	editBungii = async () => {
		toasterService.hideCustomToasty();
		try {
			const { setActiveTab } = this.props;
			setActiveTab("getEstimate");
			this.props.setBackToQuote(true);
			this.props.history.push("/request-bungii");
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * showDriverHelpCarryField()
	 * This function is used to show the message below card details.
	 */
	showDriverHelpCarryField = () => {
		try {
			const { noOfDrivers, appSetting } = this.props;
			let DisclaimerField = appSetting.PartnerLocationSettings.DisclaimerFields;
			let tripType = noOfDrivers === 1 ? "solo" : "duo";
			let that = this;
			if (DisclaimerField.length > 0) {
				DisclaimerField.forEach(function (value, i) {
					if (
						tripType === DisclaimerField[i].FieldName.toLowerCase() &&
						DisclaimerField[i].IsRequired
					) {
						that.setState({
							showCheckbox: DisclaimerField[i].IsRequired,
							disclaimerField: DisclaimerField[i].FieldValue,
						});
						that.updatedisclaimerFieldMessage();
					} else if (
						tripType === DisclaimerField[i].FieldName.toLowerCase() &&
						!DisclaimerField[i].IsRequired
					) {
						that.setState({
							showCheckbox: false,
							disclaimerField: "",
						});
					}
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * handleBtnChange()
	 * This function is called when user selects/de-selects the disclaimer message.
	 */
	handleBtnChange = (event) => {
		const { target } = event;
		try {
			if (target.checked === true) {
				this.setState({ disclaimerUnchecked: false });
			} else {
				this.setState({ disclaimerUnchecked: true });
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * cancelSubscription()
	 * This Function is called when the user clicks on close icon.
	 */
	cancelSubscription = (event) => {
		event.preventDefault();
		this.setState({
			errors: {
				...this.state.errors, password: ''
			}
		})
		if (this.state.clickedPay) {
			this.setState({
				open: false,
				clickedPay: false,
			});
		}
	};

	/**
	 * subscribe()
	 * This Function is called when the user clicks on Gain Access button.
	 */
	subscribe = async (event) => {
		event.preventDefault();
		if (this.state.password === undefined || this.state.password === '' || this.state.password === null) {
			this.setState({
				errors: {
					...this.state.errors, password: 'Password is required.'
				}
			})
			return;
		}
		const { setShowLoader } = this.props;
		try {
			setShowLoader(true);
			const { partnerLocationReference, setPaymentMethodSelected } = this.props;
			let data = {
				PartnerLocationReference: partnerLocationReference,
				Password: this.state.password,
			};

			let response = await AuthService.isUserAuthorized(data);
			if (response.data.AccessToken !== null) {
				/**
				 * Set redux state
				 */
				setPaymentMethodSelected(this.state.selectedPayMethod);
				this.setState({
					open: false,
					errors: {
						...this.state.errors,
						password: "",
					},
				});
				this.paymentMode();
			} else {
				this.setState({
					errors: {
						...this.state.errors,
						password: response.data.Error.Message + ".",
					},
				});
			}
			setShowLoader(false);
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * onPasswordChange()
	 * This function is called when user enters the password.
	 */
	onPasswordChange = (event) => {
		try {
			this.setState({ password: event.target.value });
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * validateSKU()
	 * This function is used to validate SKU field.
	 */
	validateSKU = async () => {
		const { skuNumber } = this.state;
		let isValid = false;

		let serviceData = this.state.formDetails.itemsToDeliver.filter(
			(data) => data.SKU === parseInt(skuNumber)
		);
		let dataCount = this.state.formDetails.itemsToDeliver;
		if (serviceData.length > 0) {
			this.setState({
				errors: {
					...this.state.errors,
					skunumber: languageConstants.errors.skuItemsToDeliver.itemExists,
				},
			});
		} else {
			if (dataCount.length >= globalConstants.MaxItemAllowed) {
				this.setState({
					errors: {
						...this.state.errors,
						skunumber: languageConstants.errors.skuItemsToDeliver.maxItemCount,
					},
					skuNumber: "",
				});
			} else {
				if (validateSkuNumber(skuNumber) !== "") {
					this.setState({
						errors: {
							...this.state.errors,
							skunumber: validateSkuNumber(skuNumber),
						},
					});
				} else {
					this.setState({
						errors: {
							...this.state.errors,
							skunumber: "",
						},
					});
					isValid = true;
				}
			}
		}
		return isValid;
	};

	/**
	 * addItemToList()
	 * This function is used to add the item to the list.
	 */
	addItemToList = async (itemsToDeliverData) => {
		try {
			const { setItemsToDeliver } = this.props;
			let updateformDetails = "";
			updateformDetails = { ...this.state.formDetails };
			updateformDetails["itemsToDeliver"] = [
				...this.state.formDetails.itemsToDeliver,
				...itemsToDeliverData,
			];

			/*Set Redux State*/
			setItemsToDeliver(updateformDetails["itemsToDeliver"]);

			await this.setState({
				formDetails: updateformDetails,
				skuNumber: "",
				disclaimerUnchecked: false,
			});
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * addSku()
	 * This function is used to add the item based on the SKU number.
	 */
	addSku = async () => {
		try {
			const { accessToken, setShowLoader } = this.props;
			let isValid = await this.validateSKU(),
				fetchItemData = "",
				itemsToDeliverData = "",
				weight;
			toasterService.hideCustomToasty();
			if (isValid) {
				setShowLoader(true);

				/*Fetch the data from the API.*/
				try {
					fetchItemData = await handleGetReq(
						appConfig.urls.getSkuData + this.state.skuNumber,
						"",
						accessToken
					);
					if (fetchItemData && !fetchItemData.data.Error) {
						itemsToDeliverData = fetchItemData.data.ItemDetails;
						weight = itemsToDeliverData.Weight;
						if (
							weight &&
							weight.match(/(\d+)/)[0] >= this.state.maxItemWeight
						) {
							this.setState({
								errors: {
									...this.state.errors,
									skunumber: `${languageConstants.errors.skuItemsToDeliver.maxItemWeight}${this.state.maxItemWeight}lbs.`,
								},
							});
						} else {
							await this.addItemToList([itemsToDeliverData]);
						}
					} else {
						this.setState({
							errors: {
								...this.state.errors,
								skunumber: fetchItemData.data.Error.Message,
							},
						});
					}
				} catch (err) {
					this.setState({
						errors: {
							...this.state.errors,
							skunumber: err.response.data.Error.Message,
						},
					});
				}
				setShowLoader(false);
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * setCheckedItems
	 * @param {*} checkedItems 
	 * This function will add selected items to checkedItems array
	 */
	setCheckedItems = (checkedItems)=>{
		this.setState({
			checkedItems : checkedItems,
			errors: {
				...this.state.errors,
				skunumber: "",
			}
		})
	}

	/**
	 * This function will add checked items to itemsToDeliver
	 */
	addItems = ()=>{
		// remove already added items from checkedItems
		let newItems = removeIntersection(this.state.checkedItems, this.state.formDetails.itemsToDeliver)

		newItems.forEach(item => {
			if(typeof(item.Height) === 'number')
				item.Height+= ' inches';

			if(typeof(item.Width) === 'number')
				item.Width+= ' inches';

			if(typeof(item.Depth) === 'number')
				item.Depth+= ' inches';

			if(typeof(item.Weight) === 'number')
				item.Weight+= ' lbs';

			item.Quantity = '1';
		})

		if(newItems.length > 0){
			this.setState({
				errors: {
					...this.state.errors,
					skunumber: "",
				}
			})
		}
		this.addItemToList(newItems);
	}

	/**
	 * handleRemoveClick()
	 * This function is used to remove the item.
	 */
	handleRemoveClick = async (index) => {
		try {
			const { setShowLoader, setItemsToDeliver } = this.props;
			setShowLoader(true);
			let updateformDetails = { ...this.state.formDetails };
			const skuDataList = updateformDetails["itemsToDeliver"];
			let removedItem = skuDataList.splice(index, 1);

			this.setCheckedItems(this.state.checkedItems.filter(item => item.Name !== removedItem[0].Name))
			/*Set Redux State*/
			setItemsToDeliver(skuDataList);
			setShowLoader(false);
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * updateQuantity()
	 * This function is used to update the item quantity based on the user input.
	 */
	updateQuantity = async (toUpdate, qty) => {
		const { setItemsToDeliver } = this.props;
		let updateformDetails = { ...this.state.formDetails };
		Object.keys(updateformDetails.itemsToDeliver).map(function (key, index) {
			if (key === toUpdate) {
				updateformDetails.itemsToDeliver[key]["Quantity"] = qty.toString();
			}
			return true;
		});
		setItemsToDeliver(updateformDetails["itemsToDeliver"]);
		await this.setState({
			formDetails: updateformDetails,
		});
	};

	/**
	 * updateItemQuantity()
	 * This function is used to Increment/Decrement the item quantity.
	 */
	updateItemQuantity = (e) => {
		try {
			let { PartnerLocationSettings } = this.props.appSetting;
			let itemToUpdate = "",
				currentQuantity = "",
				targetName = "";

			itemToUpdate = e.target.getAttribute("data-index");
			currentQuantity = parseInt(
				document.getElementById("quantity_" + itemToUpdate).value
			);
			targetName = e.currentTarget.getAttribute("data-field");

			if (targetName === "decrement") {
				if (currentQuantity === 1) {
					e.preventDefault();
				} else {
					currentQuantity = currentQuantity - 1;
					this.updateQuantity(itemToUpdate, currentQuantity);
				}
			} else if (targetName === "increment") {

				let maxLimit = PartnerLocationSettings.DeliveryDetailsFields[0].UIType === 4 
					? 99 : globalConstants.MaxItemAllowed
				 
				if (currentQuantity >= maxLimit) {
					e.preventDefault();
				} else {
					currentQuantity = currentQuantity + 1;
					this.updateQuantity(itemToUpdate, currentQuantity);
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * quantityKeyDown()
	 * This function is used to prevent user input in the quantity field.
	 */
	quantityKeyDown = (e) => {
		e.preventDefault();
	};

	/**
	 * appendUrl()
	 * This function is used to append the url to the message.
	 */
	appendUrl = (mge) => {
		try {
			const { appSetting } = this.props;
			let { CustomOrderURL, CustomOrderURLLabel } = "";
			if (
				appSetting &&
				appSetting.PartnerLocationSettings.DeliveryDetailsFields.length > 0
			) {
				appSetting.PartnerLocationSettings.DeliveryDetailsFields.map(
					(field, index) => {
						if (field["Fields"].length > 0) {
							CustomOrderURL = field["CustomOrderURL"];
							CustomOrderURLLabel = field["CustomOrderURLLabel"];
							return true;
						}
						return true;
					}
				);
			}
			let data = mge.split("{{custom_order_url}}");
			let dataToAppend = (
				<a href={CustomOrderURL} target="_blank" rel="noopener noreferrer">
					{CustomOrderURLLabel.charAt(0).toUpperCase() +
						CustomOrderURLLabel.slice(1)}
				</a>
			);
			if (data.length > 1) {
				return (
					<div>
						{data[0]}
						{dataToAppend}
						{data[1]}
					</div>
				);
			} else {
				return data;
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * errorHighlights()
	 * This function is used to update the red highlights of the fields(depth and width).
	 */
	errorHighlights = async (maxAllowedValue, minAllowedValue, updatedPalletData, index) => {
		if (updatedPalletData[index].Depth !== "" && (updatedPalletData[index].Depth > maxAllowedValue ||
			updatedPalletData[index].Depth < minAllowedValue)) {
			document.getElementById(`Depth_${index}`).classList.add("invalid");

		} else { document.getElementById(`Depth_${index}`).classList.remove("invalid"); }
		if (updatedPalletData[index].Width !== "" && (updatedPalletData[index].Width > maxAllowedValue ||
			updatedPalletData[index].Width < minAllowedValue)
		) {
			document.getElementById(`Width_${index}`).classList.add("invalid");
		} else { document.getElementById(`Width_${index}`).classList.remove("invalid"); }

	};


	/**
	 * validateDimensions()
	 * This function is used to validate Depth and Width fields.
	 */
	validateDimensions = async (value, appSetting, updatedPalletData, fieldErr, index, palletDatalist, j, curTarget, name) => {
		let maxAllowedValue = appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]["Fields"][1]["MaxAllowedValue"]
		let minAllowedValue = appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]["Fields"][1]["MinAllowedValue"]
		let maxValidationText = appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]["Fields"][1]["MaxValidationText"]
		let minValidationText = appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]["Fields"][1]["MinValidationText"]

		if (updatedPalletData[index].Depth > maxAllowedValue ||
			updatedPalletData[index].Width > maxAllowedValue) {
			fieldErr[index]["palletError"] = maxValidationText;
			palletDatalist[index][name] = value.indexOf(".") >= 0 ? value.slice(0, value.indexOf(".") + 3) : value;
			setPalletData(palletDatalist);
			this.props.palletErrors[index]["palletError"] = maxValidationText
			this.props.setPalletErrors(this.props.palletErrors)

			this.setState({
				errors: { ...this.state.errors, pallet: fieldErr },
			});
			await this.errorHighlights(maxAllowedValue, minAllowedValue, updatedPalletData, index)
			return true;

		} else if ((updatedPalletData[index].Depth !== "" ||
			updatedPalletData[index].Width !== "") &&
			(updatedPalletData[index].Depth < minAllowedValue ||
				updatedPalletData[index].Width < minAllowedValue)) {

			fieldErr[index]["palletError"] = minValidationText;
			this.setState({
				errors: { ...this.state.errors, pallet: fieldErr },
			});
			palletDatalist[index][name] = value.indexOf(".") >= 0 ? value.slice(0, value.indexOf(".") + 3) : value;
			setPalletData(palletDatalist);
			this.props.palletErrors[index]["palletError"] = minValidationText;
			this.props.setPalletErrors(this.props.palletErrors)
			await this.errorHighlights(maxAllowedValue, minAllowedValue, updatedPalletData, index)
			return true
		}

	};


	/**
	 * updatePallets()
	 * This function is used to update the Pallet data.
	 */
	updatePallets = async (e, index, type, j) => {
		const { appSetting } = this.props;
		const curTarget = e.currentTarget.id;
		const { setPalletData } = this.props;
		const { name, value } = e.target;
		const palletDatalist = [...this.state.formDetails.palletResults];
		let fieldErr = [...this.state.errors.pallet];
		palletDatalist[index]["ID"] = index + 1;

		//RegExp to accept only numbers and decimal
		let validNumber = new RegExp(/^\d*\.?\d*$/);

		let updatedPalletData = [...palletDatalist];
		updatedPalletData[index] = {
			...updatedPalletData[index],
			[name]: value,
		}
		if (value.length === 0) {
			palletDatalist[index][name] = value;
			setPalletData(palletDatalist);
			document.getElementById(curTarget).classList.add("invalid");
			fieldErr[index]["palletError"] = languageConstants.errors.pallets.emptyPallet;
			return this.setState({
				errors: { ...this.state.errors, pallet: fieldErr },
			});
		}
		if (type === "Numeric") {
			if (name === "Weight") {
				if (this.state.regexp.test(value) === true) {
					if (value > appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]["Fields"][j]["MaxAllowedValue"]) {
						fieldErr[index]["palletError"] =
							appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]["Fields"][j]["MaxValidationText"];
						document.getElementById(curTarget).classList.add("invalid");
						return this.setState({
							errors: { ...this.state.errors, pallet: fieldErr },
						});

					} else if (value <= appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]["Fields"][j]["MinAllowedValue"]) {
						fieldErr[index]["palletError"] =
							appSetting.PartnerLocationSettings.DeliveryDetailsFields[0][
							"Fields"
							][j]["MinValidationText"];
						document.getElementById(curTarget).classList.add("invalid");
						return this.setState({
							errors: { ...this.state.errors, pallet: fieldErr },
						});
					} else {
						let validate = await this.validateDimensions(value, appSetting, updatedPalletData, fieldErr, index, palletDatalist, j, curTarget, name)
						if (!validate) {
							fieldErr[index]["palletError"] = "";
							palletDatalist[index][name] = value;
							setPalletData(palletDatalist);
							this.setState({
								errors: { ...this.state.errors, pallet: fieldErr },
							});
							this.props.setPalletErrors(fieldErr)
						}
						await this.validatePalletFields();
						document.getElementById(curTarget).classList.remove("invalid");
					}
				} else {
					e.preventDefault();
				}
			} else if (name === "Width" || name === "Depth") {
				if(value !== '.') {
					if (validNumber.test(value)) {
						let validate = await this.validateDimensions(value, appSetting, updatedPalletData, fieldErr, index, palletDatalist, j, curTarget, name)
						if (!validate) {
							document.getElementById(curTarget).classList.remove("invalid");
							palletDatalist[index][name] = value.indexOf(".") >= 0 ? value.slice(0, value.indexOf(".") + 3) : value;
							setPalletData(palletDatalist);
							fieldErr[index]["palletError"] = "";
							this.setState({
								errors: { ...this.state.errors, pallet: fieldErr },
							});
							this.props.setPalletErrors(fieldErr);

						}
						await this.validatePalletFields();
					} else {
						e.preventDefault();
					}
				} else {
					e.preventDefault();
				}
			} else if (name === "Height") {
				if(value !== '.') {
					if (validNumber.test(value)) {
						let validate = await this.validateDimensions(value, appSetting, updatedPalletData, fieldErr, index, palletDatalist, j, curTarget, name)
						if (!validate) {
							palletDatalist[index][name] = value.indexOf(".") >= 0 ? value.slice(0, value.indexOf(".") + 3) : value;
							setPalletData(palletDatalist);
							fieldErr[index]["palletError"] = "";
							this.setState({
								errors: { ...this.state.errors, pallet: fieldErr },
							});
							this.props.setPalletErrors(fieldErr);
						}
						await this.validatePalletFields();
						document.getElementById(curTarget).classList.remove("invalid");
					} else {
						e.preventDefault();
					}
				} else {
					e.preventDefault();
				}
			} else {
				document.getElementById(curTarget).classList.remove("invalid");
				palletDatalist[index][name] = value;
				setPalletData(palletDatalist);
				fieldErr[index]["palletError"] = "";
				this.setState({
					errors: { ...this.state.errors, pallet: fieldErr },
				});
				this.props.setPalletErrors(fieldErr);
				await this.validatePalletFields();
			}
		} else {
			let validate = await this.validateDimensions(value, appSetting, updatedPalletData, fieldErr, index, palletDatalist, j, curTarget, name)
			if (!validate) {
				fieldErr[index]["palletError"] = "";
				this.setState({
					errors: { ...this.state.errors, pallet: fieldErr },
				});
				this.props.setPalletErrors(fieldErr);
				palletDatalist[index][name] = value;
				setPalletData(palletDatalist);
			}
			await this.validatePalletFields();
			document.getElementById(curTarget).classList.remove("invalid");
		}
	};
	
	/**
	 * updateXLTileData()
	 * This function is used to update the XL Tile Pallet data.
	 */
	updateXLTileData = async (e, index, type, j, StandardFieldValue) => {
		const { appSetting } = this.props;
		const curTarget = e.currentTarget.id;
		const { setPalletData } = this.props;
		const { name, value } = e.target;
		const palletDatalist = [...this.state.formDetails.palletResults];
		let fieldErr = [...this.state.errors.pallet];
		palletDatalist[index]["ID"] = index + 1;

		if (value.length === 0) {
			if (name === "noOfSlabs") palletDatalist[index]["Weight"] = "";
			palletDatalist[index][name] = value;
			setPalletData(palletDatalist);
			document.getElementById(curTarget).classList.add("invalid");
			fieldErr[index]["palletError"] = languageConstants.errors.pallets.emptyXlTilePallet;
			return this.setState({
				errors: { ...this.state.errors, pallet: fieldErr },
			});
		}
		if (type === "Numeric") {
			if (this.state.regexp.test(value) === true) {
				let weightField = appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]?.Fields?.filter(
					(item) => item.FieldName === "Weight"
				)[0]?.StandardFieldValue;

				palletDatalist[index]["Weight"] = String((value * weightField.StandardTileWeight) + weightField.StandardAFrameWeight);
				palletDatalist[index][name] = value;
				setPalletData(palletDatalist);

				if (value > appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]["Fields"][j]["MaxAllowedValue"]) {
					fieldErr[index]["palletError"] =
						appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]["Fields"][j]["MaxValidationText"];
					document.getElementById(curTarget).classList.add("invalid");
					this.props.setPalletErrors(fieldErr)
					return this.setState({
						errors: { ...this.state.errors, pallet: fieldErr },
					});

				} else if (value < appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]["Fields"][j]["MinAllowedValue"]) {
					fieldErr[index]["palletError"] =
						appSetting.PartnerLocationSettings.DeliveryDetailsFields[0][
						"Fields"
						][j]["MinValidationText"];
					document.getElementById(curTarget).classList.add("invalid");
					this.props.setPalletErrors(fieldErr)
					return this.setState({
						errors: { ...this.state.errors, pallet: fieldErr },
					});
				} else {
					fieldErr[index]["palletError"] = "";
					this.setState({
						errors: { ...this.state.errors, pallet: fieldErr },
					});
					this.props.setPalletErrors(fieldErr)
					await this.validateXLTileFields();
					document.getElementById(curTarget).classList.remove("invalid");
				}
			} else {
				e.preventDefault();
			}
		} else {
			palletDatalist[index][name] = value;
			setPalletData(palletDatalist);
			await this.validateXLTileFields();
			document.getElementById(curTarget).classList.remove("invalid");
		}
	};

	/**
	 * setTooltip()
	 * This function is used to set the tooltip
	 */
	setTooltip = async (name) => {
		try {
			await this.setState({
				[name]: !this.state[name],
			});
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * hideTooltip()
	 * This function is used to hide the tooltip
	 */
	hideTooltip = async (name) => {
		try {
			await this.setState({
				[name]: false,
			});
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * newBungii()
	 * Tis function is called on click of newBungii button.
	 */
	newBungii = async () => {
		try {
			const {
				setActiveTab,
				appSetting,
				setDeliveryDetailsInitialState,
				setCommonInitialState,
				resetDataOnPageChange,
			} = this.props;
			let defaultGeofenceVal = defaultGeofence();
			let data = {
				defaultGeofenceIndex: defaultGeofenceVal,
				defaultGeofence: appSetting.GeofenceSettings[defaultGeofenceVal],
				timeZone:
					appSetting.GeofenceSettings[defaultGeofenceVal]["TimezoneIdentifier"],
			};
			/**
			 * Set redux state
			 */
			setDeliveryDetailsInitialState();
			resetDataOnPageChange(data);
			setCommonInitialState();

			/**
			 * Clear appSetting data
			 */
			clearAppsettingData();

			setActiveTab("getEstimate");
			this.props.history.push("/request-bungii");
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * handleCloseAlert()
	 * This function is used to close the popup
	 */
	handleCloseAlert = () => {
		this.setState({
			showAlertModal: false,
		});
	};

	/**
	 * validateNumber()
	 * This function is called on customer mobile input change
	 */
	validateAdditionalPhs = (evt) => {
		if (
			this.props.multiplePhoneNumbers.length === globalConstants.maxPhoneNumbers
		) {
			let tmp = {
				currentCustomerMobile: "",
				error: "Maximum limit reached.",
			};
			this.setState({ multiplePhsFields: tmp });
			return;
		}
		const length = globalConstants.MaxAllowedDigitsInPhoneNumber;
		const { value } = evt.target;
		const { multiplePhsFields } = this.state;
		if (value.length > length) return;

		const { multiplePhoneNumbers, setMultiplePhs } = this.props;
		if (
			(!value && multiplePhsFields.currentCustomerMobile) ||
			/^[0-9 ]+$/.test(value)
		)
			this.setState(
				{
					multiplePhsFields: {
						...multiplePhsFields,
						currentCustomerMobile: value,
					},
				},
				() => {
					if (multiplePhsFields.currentCustomerMobile.length === length - 1) {
						setMultiplePhs([...multiplePhoneNumbers, value]);
						this.setState(
							{
								multiplePhsFields: {
									...multiplePhsFields,
									currentCustomerMobile: "",
								},
							},
							() => {
								if (this.state.multiplePhsFields.error) {
									let tmp = {
										currentCustomerMobile: "",
										error: "",
									};
									this.setState({ multiplePhsFields: tmp });
								}
							}
						);
					}
				}
			);
	};

	/**
	 * handleCustomerMobileChange()
	 * This function is used for adding phone number tag in customer mobile input
	 */
	handleCustomerMobileChange = (phs) => {
		if (
			phs.length < globalConstants.maxPhoneNumbers &&
			this.state.multiplePhsFields.error
		) {
			let tmp = {
				currentCustomerMobile: "",
				error: "",
			};
			this.setState({ multiplePhsFields: tmp });
		}
		const { multiplePhsFields } = this.state;
		const { multiplePhoneNumbers, setMultiplePhs } = this.props;
		if (phs.length === multiplePhoneNumbers.length - 1) {
			this.props.setMultiplePhs(phs);
			return;
		}
		const isError = this.validateCustomerMobile(
			multiplePhsFields.currentCustomerMobile
		);
		if (isError) {
			let tmp = {
				currentCustomerMobile: "",
				error: "Invalid Customer Mobile",
			};
			this.setState({ multiplePhsFields: tmp });
			return;
		}
		phs[phs.length - 1] = multiplePhsFields.currentCustomerMobile;
		setMultiplePhs(phs);
		this.setState({
			multiplePhsFields: {
				currentCustomerMobile: "",
				error: "",
			},
		});
	};

	render() {
		const {
			classes,
			pickupAdd,
			dropoffAdd,
			noOfDrivers,
			scheduledTimestamp,
			estimate,
			paymentMethodSelected,
			appSetting,
			showPrice,
			deliveryTime,
			distance,
			pickupRequestID,
			partnerUpchargeValue,
		} = this.props;
		const { PartnerLocationSettings } = appSetting;
		const { AddMultipleCustomerPhoneNumber } =
			appSetting.PartnerLocationSettings;
		/*Char limit*/
		let charLimit = 150;
		if (appSetting && appSetting.PartnerLocationSettings.StaticFields) {
			appSetting.PartnerLocationSettings.StaticFields.forEach(function (
				staticField,
				i
			) {
				saveDeliveryInformationFormConfig[staticField.FieldRef] = {};
				if (staticField.IsRequired) {
					saveDeliveryInformationFormConfig[staticField.FieldRef][
						"isRequired"
					] = languageConstants.errors.requiredField.message;
				}
				if (staticField.FieldDataType === globalConstants.FieldTypes.Phone) {
					saveDeliveryInformationFormConfig[staticField.FieldRef]["isNumber"] =
						languageConstants.errors.invalidField.message;
					if (staticField.IsRequired) {
						saveDeliveryInformationFormConfig[staticField.FieldRef][
							"isExactLength"
						] = {
							message: languageConstants.errors.invalidField.message,
							length: globalConstants.MaxAllowedDigitsInPhoneNumber,
						};
					} else {
						saveDeliveryInformationFormConfig[staticField.FieldRef][
							"isRegexMatch"
						] = {
							message: languageConstants.errors.invalidField.message,
							regex: /^\d{10}$|^(?!\d)$/,
						};
					}
					saveDeliveryInformationTransformsConfig[staticField.FieldRef] = (
						value
					) => value.replace(/\D/g, "");
				}
			});
		}

		if (appSetting && appSetting.PartnerLocationSettings.CustomFields) {
			appSetting.PartnerLocationSettings.CustomFields.forEach(function (
				customField,
				i
			) {
				saveDeliveryInformationFormConfig[customField.FieldRef] = {};
				if (customField.IsRequired) {
					saveDeliveryInformationFormConfig[customField.FieldRef][
						"isRequired"
					] = languageConstants.errors.requiredField.message;
				}
			});
		}
		let staticFields = "";
		if (appSetting && appSetting.PartnerLocationSettings.StaticFields) {
			/*Static field List*/
			staticFields = appSetting.PartnerLocationSettings.StaticFields.map(
				(field, index) => {
					let inputContainer;
					if (field["ControlType"] !== "DropDown") {
						if (field.FieldDataType === globalConstants.FieldTypes.Phone) {
							inputContainer = (
								<Validation
									config={saveDeliveryInformationFormConfig}
									initialValues={this.state.formDetails}
									transforms={saveDeliveryInformationTransformsConfig}
								>
									{({ errors, submitted, fields, setError }) => (
										<React.Fragment>
											<InputMask
												mask={globalConstants.PhoneNumberFormat}
												id={field.FieldRef}
												onChange={(e) =>
													this.handleFieldChange(
														e,
														index,
														field.FieldRef,
														field.ControlType,
														fields,
														"Static",
														setError
													)
												}
												value={fields[field.FieldRef] || ""}
											>
												{(inputProps) => (
													<Form.Control
														{...inputProps}
														className={
															submitted && errors[field.FieldRef]
																? "invalid"
																: ""
														}
														name={field.FieldRef}
														id={field.FieldRef}
														ref={field.FieldRef}
														type="text"
														autoComplete="off"
														data-field={field.MappingDBFieldName}
													/>
												)}
											</InputMask>
											<div className="invalid-feedback d-block">
												{submitted && errors[field.FieldRef]
													? `${field.DisplayName} ${errors[field.FieldRef]}`
													: ""}
											</div>
										</React.Fragment>
									)}
								</Validation>
							);
						} else if (
							field.FieldDataType === globalConstants.FieldTypes.Integer
						) {
							inputContainer = (
								<Validation
									config={saveDeliveryInformationFormConfig}
									initialValues={this.state.formDetails}
									transforms={saveDeliveryInformationTransformsConfig}
								>
									{({ errors, submitted, fields, setError }) => (
										<React.Fragment>
											<Form.Control
												maxLength={field.MaxLength}
												className={
													submitted && errors[field.FieldRef] ? "invalid" : ""
												}
												name={field.FieldRef}
												id={field.FieldRef}
												ref={field.FieldRef}
												value={fields[field.FieldRef] || ""}
												type="text"
												autoComplete="off"
												data-field={field.MappingDBFieldName}
												onChange={(e) => {
													validateNumber(e);
													this.handleFieldChange(
														e,
														index,
														field.FieldRef,
														field.ControlType,
														fields,
														"Static",
														setError
													);
												}}
												required
											/>
											<div className="invalid-feedback d-block">
												{submitted && errors[field.FieldRef]
													? `${field.DisplayName} ${errors[field.FieldRef]}`
													: ""}
											</div>
										</React.Fragment>
									)}
								</Validation>
							);
						} else {
							inputContainer = (
								<Validation
									config={saveDeliveryInformationFormConfig}
									initialValues={this.state.formDetails}
									transforms={saveDeliveryInformationTransformsConfig}
								>
									{({ errors, submitted, fields, setError }) => (
										<React.Fragment>
											<Form.Control
												maxLength={field.MaxLength}
												className={
													submitted && errors[field.FieldRef] ? "invalid" : ""
												}
												name={field.FieldRef}
												id={field.FieldRef}
												ref={field.FieldRef}
												value={fields[field.FieldRef] || ""}
												type="text"
												autoComplete="off"
												data-field={field.MappingDBFieldName}
												onChange={(e) =>
													this.handleFieldChange(
														e,
														index,
														field.FieldRef,
														field.ControlType,
														fields,
														"Static",
														setError
													)
												}
												required
											/>
											<div className="invalid-feedback d-block">
												{submitted && errors[field.FieldRef]
													? `${field.DisplayName} ${errors[field.FieldRef]}`
													: ""}
											</div>
										</React.Fragment>
									)}
								</Validation>
							);
						}
					} else {
						inputContainer = (
							<Validation
								config={saveDeliveryInformationFormConfig}
								initialValues={this.state.formDetails}
								transforms={saveDeliveryInformationTransformsConfig}
							>
								{({ errors, submitted, fields, setError }) => (
									<React.Fragment>
										<FormControl variant="outlined">
											<SelectBox
												type="customDropdown"
												value={
													fields[field.FieldRef] ? fields[field.FieldRef] : ""
												}
												onChange={(e) =>
													this.handleFieldChange(
														e,
														index,
														field.FieldRef,
														field.ControlType,
														fields,
														"Static",
														setError
													)
												}
												items={field}
												class={{ classes: { list: classes.formControl } }}
												error={
													submitted && errors[field.FieldRef] ? "invalid" : ""
												}
											/>
											<div className="invalid-feedback d-block">
												{submitted && errors[field.FieldRef]
													? `${field.DisplayName} ${errors[field.FieldRef]}`
													: ""}
											</div>
										</FormControl>
									</React.Fragment>
								)}
							</Validation>
						);
					}
					return (
						<Col sm="6" key={field.FieldRef} className="customFields">
							<Form.Group>
								<Form.Label>
									{field.DisplayName}
									{field.IsRequired ? (
										<span className="text-mandatory">*</span>
									) : (
										""
									)}
									{field.PlaceHolder ? (
										<i className="custom-placeholder">({field.PlaceHolder})</i>
									) : (
										""
									)}
								</Form.Label>
								{inputContainer}
							</Form.Group>
						</Col>
					);
				}
			);
		}
		let customFields = "";
		if (appSetting && appSetting.PartnerLocationSettings.CustomFields) {
			customFields = appSetting.PartnerLocationSettings.CustomFields.map(
				(field, index) => {
					if (field["ControlType"] === "DropDown") {
						return (
							<Col sm="6" key={field.FieldRef}>
								<Form.Group>
									<Validation
										config={saveDeliveryInformationFormConfig}
										values={this.state.formDetails}
									>
										{({ errors, submitted, fields, setError }) => (
											<React.Fragment>
												<Form.Label>
													{field.DisplayName}
													{field.IsRequired ? (
														<span className="text-mandatory">*</span>
													) : (
														""
													)}
													{field.PlaceHolder ? (
														<i className="custom-placeholder">
															({field.PlaceHolder})
														</i>
													) : (
														""
													)}
												</Form.Label>
												<FormControl variant="outlined">
													<SelectBox
														type="customDropdown"
														value={
															fields[field.FieldRef]
																? fields[field.FieldRef]
																: ""
														}
														onChange={(e) =>
															this.handleFieldChange(
																e,
																index,
																field.FieldRef,
																field.ControlType,
																fields,
																"Custom",
																setError
															)
														}
														items={field}
														class={{ classes: { list: classes.formControl } }}
														error={
															submitted && errors[field.FieldRef]
																? "invalid"
																: ""
														}
													/>
													<div className="invalid-feedback d-block">
														{submitted && errors[field.FieldRef]
															? `${field.DisplayName} ${errors[field.FieldRef]}`
															: ""}
													</div>
												</FormControl>
											</React.Fragment>
										)}
									</Validation>
								</Form.Group>
							</Col>
						);
					} else {
						return (
							<Col sm="6" key={field.FieldRef}>
								<Form.Group>
									<Form.Label>
										{field.DisplayName}
										{field.IsRequired ? (
											<span className="text-mandatory">*</span>
										) : (
											""
										)}
										{field.PlaceHolder ? (
											<i className="custom-placeholder">
												({field.PlaceHolder})
											</i>
										) : (
											""
										)}
									</Form.Label>
									<Validation
										config={saveDeliveryInformationFormConfig}
										values={this.state.formDetails}
									>
										{({ errors, submitted, fields, setError }) => (
											<React.Fragment>
												<Form.Control
													maxLength={charLimit}
													className={
														submitted && errors[field.FieldRef] ? "invalid" : ""
													}
													name={field.FieldRef}
													id={field.FieldRef}
													ref={field.FieldRef}
													type="text"
													autoComplete="off"
													onChange={(e) =>
														this.handleFieldChange(
															e,
															index,
															field.FieldRef,
															field.ControlType,
															fields,
															"Custom",
															setError
														)
													}
													value={fields[field.FieldRef] || ""}
													required
												/>
												<div className="invalid-feedback d-block">
													{submitted && errors[field.FieldRef]
														? `${field.DisplayName} ${errors[field.FieldRef]}`
														: ""}
												</div>
											</React.Fragment>
										)}
									</Validation>
								</Form.Group>
							</Col>
						);
					}
				}
			);
		}

		// This code is to calculate total weight of items added in items to deliver
		let totalItemsWeight = this.state.formDetails.itemsToDeliver.reduce(
			(totalWeight, item) => 
				totalWeight + (Number(item.Weight?.split(" ")[0]) * Number(item.Quantity)) 
			, 0)
		
		let totalItemsVolume = 0.0;
		this.state.formDetails.itemsToDeliver.forEach(item => {
			totalItemsVolume += item.Volume * Number(item.Quantity);
		})
		totalItemsVolume = parseFloat(totalItemsVolume).toFixed(2)

		// flag to check if total weight exceeding max weight limit
		let MaxItemWeight = PartnerLocationSettings.DeliveryDetailsFields[0].MaxItemWeight
		let MaxItemVolume = PartnerLocationSettings.DeliveryDetailsFields[0].MaxItemVolume
		let isWeightExceedingMaxLimit = !!MaxItemWeight && (MaxItemWeight < totalItemsWeight)
		let isVolumeExceedingMaxLimit = !!MaxItemVolume && (MaxItemVolume < totalItemsVolume)
		
		const specialInstructionsMaxLength =
			globalConstants.MaxLength.SpecialInstructions;
		const { formDetails, whatsNeededData } = this.state;

       // Footer for American signature portal table.
		const TableFooter = () => {
			return (
				<tfoot
					className={
						PartnerLocationSettings.DropDownItems[0].Weight && PartnerLocationSettings.DropDownItems[0].Volume
							? '' : 'd-none'
					}
				>{
						<tr>
							<td>
								Total:
							</td>
							<td>{
								// If 1st item has no weight, don't show total weight count
								PartnerLocationSettings.DropDownItems[0].Weight &&
								<>Weight = {totalItemsWeight}{MaxItemWeight ? `/${MaxItemWeight}` : ''} lbs</>
							}</td>
							<td colSpan="4">{
								// If 1st item has no volume, don't show total volume count
								PartnerLocationSettings.DropDownItems[0].Volume &&
								<>Volume = {totalItemsVolume}{MaxItemVolume ? `/${MaxItemVolume}` : ''} (ft<sup>3</sup>)</>
							}
							</td>
						</tr>}
				</tfoot>
			)
		}
		
		//Columns for American signature portal table.
		const asiColumns = [
			{ title: 'Item', value: "Name" },
			{
				title: "Dimensions (in)",
				value: "Name",
				formatter: (data, i) => {
					return (data.Width && data.Depth && data.Height ?
						<>
							{data.Width?.split(" ")[0]} W x {" "}
							{data.Depth?.split(" ")[0]} D x {" "}
							{data.Height?.split(" ")[0]} H
						</> : "NA")
				},
			},
			{
				title: <React.Fragment >Volume (ft<sup>3</sup>)</React.Fragment>,
				value: "Volume",
				formatter: (data, i) => {
					return (data.Volume ?? 'N/A')
				},
			},
			{
				title: "Weight (lbs)", value: "Weight",
				formatter: (data, i) => { return (data.Weight ? data.Weight?.split(" ")[0] : 'N/A') },
			},
			{
				title: "Quantity", value: "Quantity",
				formatter: (data, i) => {
					return (
						<React.Fragment >
							<input
								type="text"
								autoComplete="off"
								id={"quantity_" + i}
								name={"quantity_" + i}
								className="quantity"
								onChange={(e) =>
									this.quantityKeyDown(e)
								}
								data-index={i}
								value={data.Quantity}
								onKeyDown={(e) =>
									this.quantityKeyDown(e)
								}
							/>
							<div className="counter">
								<img
									src={arrowDown}
									className="close-details decrement"
									data-field="decrement"
									onClick={(e) =>
										this.updateItemQuantity(e)
									}
									data-index={i}
									alt="Arrow Down"
								/>
								<img
									src={arrowDown}
									className="increment"
									data-field="increment"
									onClick={(e) =>
										this.updateItemQuantity(e)
									}
									data-index={i}
									alt="Arrow Up"
								/>
							</div>
						</React.Fragment>
					);
				},
			},
			{
				title: "", value: "",
				formatter: (data, i) => {
					return (
						<React.Fragment >
							<div onClick={() =>this.handleRemoveClick(i)} className="cursor-pointer">
								<img src={deleteicon} alt="Remove" />
							</div>
						</React.Fragment>
					);
				},
			},
		]
		return (
			<React.Fragment>
				{/* <h1>Delivery Details</h1> */}
				<h1>
					Delivery Details{" "}
					<span className="back" onClick={this.editBungii}>
						back to{" "}
						{appSetting.PartnerLocationSettings.EstimatePageLabel ===
							"Get Quote"
							? "quote"
							: "estimate"}
					</span>
				</h1>
				<Row>
					<Col xs="12" lg="8">
						<CalidationForm
							className="form-content delivery-details"
							onSubmit={this.submitDetails.bind(this)}
							noValidate
							config={saveDeliveryInformationFormConfig}
						>
							<React.Fragment>
								{/* Partners whos allow multiple option is Off */}
								{!appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]
									.AllowMultiple &&
									appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]
										.UIType === 1 && (
										<ItemsToDeliver
											formDetails ={this.state.formDetails}
											textChange={this.textChange}
										/>
									)}
								{/* Partners whos allow multiple option is On */}
								{appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]
									.AllowMultiple &&
									appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]
										.UIType === 2 && (
											<SkuForm
												formDetails={this.state.formDetails}
												textChange={this.textChange}
												skuNumber={this.state.skuNumber}
												addSku={this.addSku}
												errors={this.state.errors}
												quantityKeyDown={this.quantityKeyDown}
												updateItemQuantity={this.updateItemQuantity}
												handleRemoveClick={this.handleRemoveClick}
												tableData={this.state.formDetails.itemsToDeliver}
											/>
									)}
								{/* Partners with Pallet Information */}
								{!appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]
									.AllowMultiple &&
									appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]
										.UIType === 3 && (
										<PalletForm
											palletTypeRef={this.palletTypeRef}
											errors={this.state.errors}
											appendUrl={this.appendUrl}
											formDetails={this.state.formDetails}
											updatePallets={this.updatePallets}
										/>
									)}
								{/* For XL tile Partners*/}
								{!appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]
									.AllowMultiple &&
									appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]
										.UIType === 5 && (
										<div className="items-to-deliver">
											<Form.Group>
												<Form.Label className="d-block">
													Items to Deliver
													<i
														onClick={this.setTooltip.bind(
															this,
															"showPalletTypeTooltip"
														)}
														ref={this.palletTypeRef}
														className="infoIcon"
													/>
													<TooltipOverlay
														target={this.palletTypeRef.current}
														showTooltip={this.state.showPalletTypeTooltip}
														hideTooltip={this.hideTooltip.bind(
															this,
															"showPalletTypeTooltip"
														)}
													>
													<p>
														{
															appSetting.PartnerLocationSettings.DeliveryDetailsFields.map((item) => {
																if (item.FieldName === "ItemsToDeliver") {
																	return (item.Fields.map((data) => {
																		if (data.FieldName === "Weight") {
																			return `${languageConstants.pallets.palletInfo + " " + data.MaxAllowedValue} lbs.`;
																		}else return null;
																	}))
																}else return null
															})
														}
													</p>
													</TooltipOverlay>
												</Form.Label>
												{this.state.formDetails.palletResults &&
													this.state.formDetails.palletResults.map((x, i) => {
														return (
															<div key={i}>
																<Form.Label className="d-block pallet">
																	Pallet {i + 1}
																</Form.Label>
																<Row className="product-desc">
																	{appSetting.PartnerLocationSettings.DeliveryDetailsFields[0].Fields.map(
																		(y, j) => {
																			return (
																				<Col key={j} md={j === 0 ? "6" : "3"}>
																					<Form.Label className="d-block">
																						{y.FieldLabel}
																						{y.FieldName !== "Weight" && <span className="text-mandatory">
																							*
																						</span>}
																					</Form.Label>
																					<Form.Control
																						name={y.FieldName}
																						maxLength={y.FieldLengthMax}
																						id={`${y.FieldName}_${i}`}
																						data-index={i}
																						onChange={(e) =>
																							this.updateXLTileData(
																								e,
																								i,
																								y.FieldType,
																								j,
																							)
																						}
																						type="text"
																						autoComplete="off"
																						placeholder={y.WatermarkLabel}
																						value={
																							y.FieldName === "Name"
																								? x.Name
																								: y.FieldName === "noOfSlabs"
																									? x.noOfSlabs
																									: y.FieldName === "Weight"
																										? x.Weight
																										: ""
																						}
																						disabled={!y.IsFieldEditable}
																					/>
																				</Col>
																			)

																		}
																	)}
																	{this.state.errors.pallet &&
																		this.state.errors.pallet.length > 0 && (
																			<Row className="pl15">
																				<Col
																					id={"error" + i}
																					className="invalid-feedback d-block"
																				>
																					{this.appendUrl(
																						this.state.errors.pallet[i][
																						"palletError"
																						]
																					)}
																				</Col>
																			</Row>
																		)}
																</Row>
															</div>
														);
													})}
											</Form.Group>
										</div>
									)}
								{appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]
									.AllowMultiple &&
									appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]
										.UIType === 4 && (
											<div>
											<Form.Group className='mb-5'>
												<Form.Label className="d-block">
													Items to Deliver
													<span className="text-mandatory">*</span>
												</Form.Label>
												<Row>
													<Col xs={8} md={6}>
														<SelectItemsToDeliver 
															checkedItems={this.state.checkedItems}
															setCheckedItems={this.setCheckedItems}
															cartItems={this.state.formDetails.itemsToDeliver}
															error={this.state.errors.skunumber}
														/>
													</Col>
													<Col xs={2}>
														<Button
															id="add-items-btn"
															type="button"
															onClick={this.addItems}
															disabled={removeIntersection(this.state.checkedItems, this.state.formDetails.itemsToDeliver).length === 0}
														>
															ADD
														</Button>
													</Col>
												</Row>
												{this.state.errors.skunumber && (
													<div className="invalid-feedback d-block">
														{this.state.errors.skunumber}
													</div>
												)}
											</Form.Group>

											{this.state.formDetails.itemsToDeliver &&
												this.state.formDetails.itemsToDeliver.length > 0 && (
													<div>
														<DeliveryDetailsTable
															tableHeader={asiColumns}
															tableData={this.state.formDetails.itemsToDeliver}
															tableClass={"items-table"}
															tableFooter={<TableFooter/>}
														/>
													</div>
												)}
											</div>
									)}
								<Form.Group>
									<Form.Label>
										{
											appSetting.PartnerLocationSettings
												.DeliveryDetailsFields[1].DisplayLabel
										}
									</Form.Label>
									<Form.Control
										as="textarea"
										rows={2}
										maxLength={specialInstructionsMaxLength}
										name="specialInstructions"
										type="text"
										autoComplete="off"
										placeholder={
											appSetting.PartnerLocationSettings
												.DeliveryDetailsFields[1].WatermarkLabel
										}
										onChange={this.textChange.bind(this)}
										value={this.state.formDetails.specialInstructions}
									/>
									<div className="float-right form-text small">
										Characters remaining:{" "}
										{formDetails.specialInstructions
											? specialInstructionsMaxLength -
											formDetails.specialInstructions.length
											: specialInstructionsMaxLength}
										/{specialInstructionsMaxLength}
									</div>
								</Form.Group>

								{/* Static Fields will be listed here */}

								<h2>Pickup &amp; Delivery Information</h2>
								{this.state.formDetails && (
									<Row>
										<Col sm="6">
											<Validation
												config={saveDeliveryInformationFormConfig}
												initialValues={this.state.formDetails}
												transforms={saveDeliveryInformationTransformsConfig}
											>
												{({ errors, submitted, fields }) => (
													<Form.Group>
														<Form.Label>
															Customer Name (First & Last)
															<span className="text-mandatory">*</span>
														</Form.Label>
														<Form.Control
															maxLength={100}
															className={
																submitted && errors.customerName
																	? "invalid"
																	: ""
															}
															name="customerName"
															type="text"
															autoComplete="off"
															onChange={this.textChange.bind(this)}
															value={this.state.formDetails.customerName}
															required
														/>
														<div className="invalid-feedback d-block">
															{submitted && errors.customerName
																? `Customer full name ${errors.customerName}`
																: ""}
														</div>
													</Form.Group>
												)}
											</Validation>
										</Col>

										<Col
											sm="6"
											className={
												AddMultipleCustomerPhoneNumber ? "cust-phone" : ""
											}
										>
											<Validation
												config={saveDeliveryInformationFormConfig}
												initialValues={this.state.formDetails}
												transforms={saveDeliveryInformationTransformsConfig}
											>
												{({ errors, submitted, fields }) => (
													<Form.Group
														className={
															AddMultipleCustomerPhoneNumber ? "mb-0" : ""
														}
													>
														<Form.Label>
															Customer Mobile
															<span className="text-mandatory">*</span>
														</Form.Label>
														<InputMask
															mask={globalConstants.PhoneNumberFormat}
															onChange={this.textChange.bind(this)}
															value={fields.customerMobile}
														>
															{(inputProps) => (
																<Form.Control
																	{...inputProps}
																	className={
																		submitted && errors.customerMobile
																			? "invalid"
																			: ""
																	}
																	name="customerMobile"
																	type="text"
																	autoComplete="off"
																	required
																/>
															)}
														</InputMask>
														<div className="invalid-feedback d-block">
															{submitted && errors.customerMobile
																? `Customer mobile ${errors.customerMobile}`
																: ""}
														</div>
													</Form.Group>
												)}
											</Validation>
											{AddMultipleCustomerPhoneNumber && (
												<span
													className={
														"add-btn" +
														(this.props.multiplePhoneNumbers.length === 3
															? " disabled"
															: "")
													}
													title="Add additional recipients for text updates"
													onClick={this.addCustomerMobile}
												>
													ADD SMS RECIPIENTS
												</span>
											)}
										</Col>
										{this.state.formDetails &&
											this.props.multiplePhoneNumbers?.map((item, i) => {
												const inputName = `customerMobile${i}`;
												if (
													Object.keys(this.state.formDetails).includes(
														inputName
													)
												)
													return (
														<Col
															sm="6"
															className="cust-phone mb-0"
															key={`ph-no-${i}`}
														>
															<Validation
																config={{}}
																initialValues={this.state.formDetails}
																transforms={
																	saveDeliveryInformationTransformsConfig
																}
															>
																{({ errors, submitted, fields }) => {
																	fields[inputName] = item;
																	if (
																		this.validateCustomerMobile(
																			fields[inputName]
																		)
																	) {
																		errors[inputName] = "is invalid.";
																	} else {
																		errors[inputName] = null;
																	}
																	return (
																		<Form.Group>
																			<Form.Label>
																				Mobile Phone
																				<i className="custom-placeholder">
																					(We'll also text this number delivery
																					updates)
																				</i>
																			</Form.Label>
																			<InputMask
																				mask={globalConstants.PhoneNumberFormat}
																				onChange={(e) =>
																					this.customerMobileChangeHandler(i, e)
																				}
																				value={item}
																			>
																				{(inputProps) => (
																					<Form.Control
																						{...inputProps}
																						className={
																							submitted && errors[inputName]
																								? "invalid"
																								: ""
																						}
																						name={inputName}
																						type="text"
																						autoComplete="off"
																						required
																					/>
																				)}
																			</InputMask>
																			<div className="invalid-feedback d-block">
																				{submitted && errors[inputName]
																					? `Recipient ${i + 1} ${errors[inputName]
																					}`
																					: ""}
																			</div>
																		</Form.Group>
																	);
																}}
															</Validation>
															<img
																src={xicon}
																alt="Remove"
																onClick={() => this.removeCustomerMobile(i)}
																className="close"
															/>
														</Col>
													);

												else return null;
											})}
									</Row>
								)}

								<Row>
									{this.state.formDetails && staticFields.length !== 0
										? staticFields
										: ""}
								</Row>

								{/* Custom Fields will be listed here */}
								{customFields.length !== 0 ? (
									<React.Fragment>
										<h2>Additional Information</h2>
										<Row className="customFields">{customFields}</Row>
									</React.Fragment>
								) : (
									<React.Fragment></React.Fragment>
								)}

								{/* Payment methods */}
								{this.state.showPaymentMethod ? (
									<React.Fragment>
										<h2>Payment Methods</h2>
										<Form.Group className="payment-methods">
											<Row className="col-padding">
												{this.state.paymentMethod.map((method) => {
													let paymentLabel;
													switch (method.PaymentCode) {
														case "CC":
															paymentLabel = "Customer Card";
															break;
														case "PC":
															paymentLabel = "Partner Pay";
															break;
														case "MI":
															paymentLabel = "Partner Invoice";
															break;
														default:
															paymentLabel = "Unknown";
															break;
													}

													return (
														<Col md="4" key={method.PaymentCode}>
															<Form.Check
																type="radio"
																id={method.PaymentCode}
																label={paymentLabel}
																value={method.PaymentCode}
																name="payment-type"
																className="payment-method mb-2"
																checked={
																	paymentMethodSelected === method.PaymentCode
																}
																onChange={(event) =>
																	this.onRadioButtonChange(event)
																}
															/>
														</Col>
													);
												})}
											</Row>
										</Form.Group>
									</React.Fragment>
								) : (
									""
								)}

								{paymentMethodSelected === "CC" &&
									this.state.clientToken &&
									!this.state.nonce ? (
									<div>
										<DropIn
											options={{ authorization: this.state.clientToken }}
											onInstance={(instance) => (this.instance = instance)}
										/>
									</div>
								) : (
									""
								)}

								<React.Fragment>
									{this.state.showCheckbox && (
										<Form.Group
											id="helperCheckbox"
											style={{
												color: this.state.disclaimerUnchecked
													? "#CE230C"
													: "#131415",
											}}
											className="mandateCheckbox full-width"
										>
											<Form.Check
												type="checkbox"
												id="DisclaimerField"
												label={this.state.disclaimerField}
												onClick={this.handleBtnChange}
											/>
										</Form.Group>
									)}
									<div className="text-center text-md-left">
										<button
											id="submit-details"
											className="btn"
											type="submit"
											disabled={this.state.disableScheduleBtn}
										>
											Schedule Bungii{" "}
											{/* <img src={arrowIcon} alt="Schedule Bungii" width="14" /> */}
										</button>
									</div>
									<Validation
										config={saveDeliveryInformationFormConfig}
										initialValues={this.state.formDetails}
										transforms={saveDeliveryInformationTransformsConfig}
									>
										{({ errors, submitted, fields, isValid }) => (
											<React.Fragment>
												{submitted &&
													(!isValid || this.state.disclaimerUnchecked) ? (
													<Row>
														<Col
															sm="12"
															className="err-msg text-danger warning-icon mt-3"
														>
															Please verify the highlighted fields above.
														</Col>
													</Row>
													) : (submitted && (this.state.errors.maxWeight || this.state.errors.maxVolume)
													&& (isWeightExceedingMaxLimit || isVolumeExceedingMaxLimit)) ? (
													<Row>
														<Col
															sm="12"
															className="err-msg text-danger warning-icon mt-3"
														>
															{MaxItemVolume && <span>
																{languageConstants.errors.skuItemsToDeliver
																.maxItemVolume} {MaxItemVolume} ft<sup>3</sup> 
															</span>}
															{MaxItemVolume && MaxItemWeight && <> and </>}
															<span>
																{languageConstants.errors.skuItemsToDeliver
																	.maxItemWeight2} {MaxItemWeight} lbs
															</span>
															.
														</Col>
													</Row>
												): ""}
											</React.Fragment>
										)}
									</Validation>
								</React.Fragment>
							</React.Fragment>
						</CalidationForm>
					</Col>
					<Col lg="4" className="d-none d-lg-block">
						<section className="summary-container grey">
							<h2 className="summary-label">
								Summary{" "}
								<span className="edit-label" onClick={this.editBungii}>
									EDIT
								</span>
							</h2>
							{this.state.isVisibleSoloDuo && (
								<div>
									<label>What’s needed:</label>
									<p>
										{
											whatsNeededData.UIType === 6 ?
												(this.props.isMuscleHelpRequired ? "Large item(s) - 2 drivers" : this.props.tripType + " Pallet" + (this.props.tripType > 1 ? "s" : ""))
												: (noOfDrivers === 1
													? whatsNeededData.SoloSummarysectionvalue
													: whatsNeededData.DuoSummarysectionvalue)
										}
									</p>
								</div>
							)}
							<div>
								<label>Pickup Address:</label>
								<p>{pickupAdd}</p>
							</div>
							<div>
								<label>Delivery Address:</label>
								<p>{dropoffAdd}</p>
							</div>
							{this.state.serviceName && (
								<div>
									<label>Service Level:</label>
									<p>
										{this.state.serviceName +
											" - " +
											this.state.serviceDescription}
									</p>
								</div>
							)}
							<div>
								<label>Pickup Date &amp; Time:</label>
								<p>{scheduledTimestamp}</p>
							</div>
							<div>
								<label>Estimated Delivery Time:</label>
								<p>{deliveryTime}</p>
							</div>
							<div>
								<label>Distance:</label>
								<p>{distance} miles</p>
							</div>
							<div>
								<label>Payment:</label>
								{paymentMethodSelected === "MI" && (
									<p>This will be included on your invoice.</p>
								)}
								{paymentMethodSelected === "CC" && <p>Customer Card</p>}
								{paymentMethodSelected === "PC" && <p>Partner Pay</p>}
							</div>
							<div>
								<h2 className="estimate-label">
									{appSetting.PartnerLocationSettings.EstimatePageLabel ===
										"Get Quote"
										? "Delivery Cost"
										: "Estimated Cost"}
									<span>
										<strong>
											{showPrice
												? appSetting.PartnerLocationSettings
													?.EstimatePageFields[0]?.IsPartnerUpcharge
													? partnerUpchargeValue.split("-")[0]
													: estimate
												: languageConstants.defaultPrice.bestBuy}
										</strong>
									</span>
								</h2>
							</div>
						</section>
					</Col>
				</Row>
				<Password
					open={this.state.open}
					cancelSubscription={this.cancelSubscription}
					subscribe={this.subscribe}
					onPasswordChange={this.onPasswordChange}
					value={this.state.value}
					authenticateUser={this.authenticateUser}
					errors={this.state.errors.password}
					clickedPay={this.state.clickedPay}
				/>

				{this.state.showAlertModal && (
					<PickupExists
						showAlertModal={this.state.showAlertModal}
						resetBungii={this.newBungii}
						handleCloseAlert={this.handleCloseAlert}
						pickupRequestID={pickupRequestID}
					/>
				)}
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(
	connect(
		({
			getEstimate: {
				editPickup,
				pickupPoint,
				minDate,
				geofence,
				defaultGeofenceIndex,
				rerenderDropoff,
				pickupDate,
				isDuo,
				defaultPoint,
				pickupAdd,
				pickupAddObj,
				defaultAdd,
				loadUnloadTimeShow,
				defaultGeofence,
				pickupTime,
				tripType,
				dropoffAdd,
				dropoffPoint,
				timeZone,
				dropoffAddObj,
				nextCalculatedDate,
				pickupTimeValues,
				loadUnload,
				estimate,
				loadTime,
				pickupRequestID,
				serviceLevel,
				serviceName,
				deliveryTime,
				distance,
				pickupAddressess,
				partnerUpchargeValue,
				orderRef,
				isMuscleHelpRequired,
				isServiceLevelUpdated,
				isBoxTruck,
			},
			user: { accessToken, partnerLocationReference, quickAccessUser },
			common: {
				appSetting,
				activeTab,
				showLoader,
				formFilled,
				kioskMode,
				showPrice,
			},
			deliveryDetails: {
				pickupDetails,
				loadingUnloadingTime,
				noOfDrivers,
				scheduledTimestamp,
				lastFour,
				paymentMethodSelected,
				staticFields,
				customFields,
				customerName,
				customerMobile,
				pickUpNote,
				specialInstructions,
				itemsToDeliver,
				orderNumber,
				externalIdLabel,
				palletData,
				pickupContactName,
				pickupContactPhone,
				multiplePhoneNumbers,
				palletErrors,
				backToQuote,
			},
		}) => ({
			accessToken,
			activeTab,
			pickupRequestID,
			appSetting,
			pickupDetails,
			loadingUnloadingTime,
			noOfDrivers,
			scheduledTimestamp,
			editPickup,
			pickupPoint,
			minDate,
			geofence,
			defaultGeofenceIndex,
			rerenderDropoff,
			pickupDate,
			isDuo,
			defaultPoint,
			pickupAdd,
			pickupAddObj,
			defaultAdd,
			loadUnloadTimeShow,
			defaultGeofence,
			pickupTime,
			tripType,
			orderRef,
			dropoffAdd,
			dropoffPoint,
			timeZone,
			dropoffAddObj,
			nextCalculatedDate,
			pickupTimeValues,
			loadUnload,
			estimate,
			loadTime,
			lastFour,
			paymentMethodSelected,
			showLoader,
			formFilled,
			partnerLocationReference,
			serviceLevel,
			serviceName,
			kioskMode,
			staticFields,
			customFields,
			customerName,
			customerMobile,
			pickUpNote,
			specialInstructions,
			quickAccessUser,
			showPrice,
			itemsToDeliver,
			orderNumber,
			externalIdLabel,
			deliveryTime,
			distance,
			palletData,
			pickupContactName,
			pickupContactPhone,
			pickupAddressess,
			multiplePhoneNumbers,
			partnerUpchargeValue,
			palletErrors,
			isMuscleHelpRequired,
			backToQuote,
			isServiceLevelUpdated,
			isBoxTruck,
		}),
		(dispatch) => ({
			setActiveTab: (activeTab) => dispatch(setActiveTab(activeTab)),
			setPickupRequestID: (pickupRequestID) =>
				dispatch(setPickupRequestID(pickupRequestID)),
			setPickupDetails: (pickupDetails) =>
				dispatch(setPickupDetails(pickupDetails)),
			setLoadingUnloadingTime: (loadingUnloadingTime) =>
				dispatch(setLoadingUnloadingTime(loadingUnloadingTime)),
			setPickupAdd: (pickupAdd) => dispatch(setPickupAdd(pickupAdd)),
			setDropoffAdd: (dropoffAdd) => dispatch(setDropoffAdd(dropoffAdd)),
			setNoOfDrivers: (noOfDrivers) => dispatch(setNoOfDrivers(noOfDrivers)),
			setScheduledTimestamp: (scheduledTimestamp) =>
				dispatch(setScheduledTimestamp(scheduledTimestamp)),
			setLastFour: (lastFour) => dispatch(setLastFour(lastFour)),
			setPaymentMethodSelected: (paymentMethodSelected) =>
				dispatch(setPaymentMethodSelected(paymentMethodSelected)),
			setShowLoader: (showLoader) => dispatch(setShowLoader(showLoader)),
			setFormIsFilled: (formFilled) => dispatch(setFormIsFilled(formFilled)),
			setKioskMode: (kioskMode) => dispatch(setKioskMode(kioskMode)),
			setStaticFields: (staticFields) =>
				dispatch(setStaticFields(staticFields)),
			setCustomFields: (customFields) =>
				dispatch(setCustomFields(customFields)),
			setCustomerName: (customerName) =>
				dispatch(setCustomerName(customerName)),
			setCustomerMobile: (customerMobile) =>
				dispatch(setCustomerMobile(customerMobile)),
			setPickUpNote: (pickUpNote) => dispatch(setPickUpNote(pickUpNote)),
			setSpecialInstructions: (specialInstructions) =>
				dispatch(setSpecialInstructions(specialInstructions)),
			setAppSetting: (appSetting) => dispatch(setAppSetting(appSetting)),
			setItemsToDeliver: (itemsToDeliver) =>
				dispatch(setItemsToDeliver(itemsToDeliver)),
			setOrderNumber: (orderNumber) => dispatch(setOrderNumber(orderNumber)),
			setExternalIdLabel: (externalIdLabel) => dispatch(setExternalIdLabel(externalIdLabel)),
			setPalletData: (palletData) => dispatch(setPalletData(palletData)),
			setPickupContactName: (pickupContactName) =>
				dispatch(setPickupContactName(pickupContactName)),
			setPickupContactPhone: (pickupContactPhone) =>
				dispatch(setPickupContactPhone(pickupContactPhone)),
			setCommonInitialState: (commonInitialState) =>
				dispatch(setCommonInitialState(commonInitialState)),
			resetDataOnPageChange: (data) => dispatch(resetDataOnPageChange(data)),
			setDeliveryDetailsInitialState: (newValues1) =>
				dispatch(setDeliveryDetailsInitialState(newValues1)),
			setPickupToken: (pickupToken) => dispatch(setPickupToken(pickupToken)),
			setMultiplePhs: (phs) => dispatch(setMultiplePhs(phs)),
			setPalletErrors: (palletErrors) => dispatch(setPalletErrors(palletErrors)),
			setBackToQuote: (backToQuote) => dispatch(setBackToQuote(backToQuote)),
		})
	)(withRouter(DeliveryDetails))
);
