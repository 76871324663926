import React, { useState } from "react";
import { useSelector } from "react-redux";
import languageConstants from "../shared/appConfig/languageConstants";
import { Col, Form, Row } from "react-bootstrap";
import TooltipOverlay from '../pages/TooltipOverlay';

const PalletForm = ({palletTypeRef, errors, appendUrl, formDetails, updatePallets} ) => {
    const [showPalletTypeTooltip, setShowPalletTypeTooltip ] = useState(false);
    const { appSetting } = useSelector((state) => state.common)
	
    return (
		<div className="items-to-deliver">
			<Form.Group>
				<Form.Label className="d-block">
					Items to Deliver
					<i
						onClick={() => setShowPalletTypeTooltip(!showPalletTypeTooltip)}
						ref={palletTypeRef}
						className="infoIcon"
					/>

					<TooltipOverlay
						target={palletTypeRef.current}
						showTooltip={showPalletTypeTooltip}
						hideTooltip={()=>setShowPalletTypeTooltip(false)}
					>
						<p>
							{appSetting?.PartnerLocationSettings?.DeliveryDetailsFields?.map(
								(item) => {
									if (item?.FieldName === "ItemsToDeliver") {
										return item?.Fields?.map((data) => {
											if (data?.FieldName === "Weight") {
												return `${
													languageConstants.pallets.palletInfo +
													" " +
													data?.MaxAllowedValue
												} lbs.`;
											} else return null;
										});
									} else return null;
								},
							)}
						</p>
					</TooltipOverlay>
				</Form.Label>
				{formDetails.palletResults?.map((data, dataIndex) => {
						return (
							<div key={dataIndex}>
								<Form.Label className="d-block pallet">
									Pallet {dataIndex + 1}
								</Form.Label>
								<Row className="product-desc">
									{appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]?.Fields.map(
										(item, index) => {
											if (
												appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]?.Fields.some(
													(data) => data?.FieldName === "Dimensions",
												)
											) {
												return (
													<Col key={index} md={index === 0 ? "6" : "3"}>
														<Form.Label className="d-block">
															{item.FieldLabel}
															<span className="text-mandatory">*</span>
														</Form.Label>
														<Form.Control
															name={item.FieldName}
															maxLength={item.FieldLengthMax}
															id={`${item.FieldName}_${dataIndex}`}
															data-index={dataIndex}
															onChange={(e) =>
																updatePallets(e, dataIndex, item.FieldType, index)
															}
															type="text"
															autoComplete="off"
															placeholder={item.WatermarkLabel}
															value={
																item.FieldName === "Name"
																	? data.Name
																	: item.FieldName === "Dimensions"
																	? data.Dimensions
																	: item.FieldName === "Weight"
																	? data.Weight
																	: ""
															}
														/>
													</Col>
												);
											} else {
												return (
													<Col
														key={index}
														md={index === 0 ? "12" : index === 4 ? "3" : "2"}
														className={
															index === 1 || index === 2 ? "pr0 w33" : index === 3 && "w33"
														}
													>
														<Row
															className={
																(item.FieldName === "Height" ||
																	item.FieldName === "Weight") &&
																"pr15"
															}
														>
															<Col
																md={
																	(item.FieldName === "Depth" ||
																		item.FieldName === "Width") &&
																	10
																}
																className={
																	(item.FieldName === "Depth" ||
																		item.FieldName === "Width" ||
																		item.FieldName === "Height" ||
																		item.FieldName === "Weight") &&
																	"pr0 w80"
																}
															>
																<Form.Control
																	className="mb10 dimensions-input"
																	name={item.FieldName}
																	maxLength={item.FieldLengthMax}
																	id={`${item.FieldName}_${dataIndex}`}
																	data-index={dataIndex}
																	onChange={(e) =>
																		updatePallets(e, dataIndex, item.FieldType, index)
																	}
																	type="text"
																	autoComplete="off"
																	placeholder={item.WatermarkLabel}
																	value={
																		item.FieldName === "Name"
																			? data.Name
																			: item.FieldName === "Depth"
																			? data.Depth
																			: item.FieldName === "Width"
																			? data.Width
																			: item.FieldName === "Height"
																			? data.Height
																			: item.FieldName === "Weight"
																			? data.Weight
																			: ""
																	}
																/>
																<span className="unit">
																	{item.FieldUnit ? item.FieldUnit : ""}
																</span>
															</Col>
															{(item.FieldName === "Depth" ||
																item.FieldName === "Width") && (
																<Col md={2} className="multiply-sign w20">
																	{" "}
																	X
																</Col>
															)}
														</Row>
													</Col>
												);
											}
										},
									)}
									{errors.pallet?.length > 0 && (
											<Row className="pl15">
												<Col
													id={"error" + dataIndex}
													className="invalid-feedback d-block"
												>
													{appendUrl(
														errors.pallet[dataIndex]["palletError"],
													)}
												</Col>
											</Row>
										)}
								</Row>
							</div>
						);
					})}
			</Form.Group>
		</div>
    );
};

export default PalletForm;