import React from "react";
import { useSelector } from "react-redux";
import { Validation } from "calidation";
import globalConstants from "../shared/appConfig/globalConstants";
import languageConstants from "../shared/appConfig/languageConstants";
import { Form } from "react-bootstrap";

let saveDeliveryInformationFormConfig = {
	customerMobile: {
		isRequired: languageConstants.errors.requiredField.message,
		isNumber: languageConstants.errors.invalidField.message,
		isExactLength: {
			message: languageConstants.errors.invalidField.message,
			length: globalConstants.MaxAllowedDigitsInPhoneNumber,
		},
	},
	customerName: {
		isRequired: languageConstants.errors.requiredField.message,
	},
	pickUpNote: {
		isRequired: languageConstants.errors.requiredField.message,
	},
};

const saveDeliveryInformationTransformsConfig = {
	customerMobile: (value) => value.replace(/\D/g, ""),
};

const itemsToDeliverMaxLength = globalConstants.MaxLength.ItemsToDeliver;

const ItemsToDeliver = ({formDetails, textChange}) => {
	const { appSetting } = useSelector((state) => state.common);
	const { PartnerLocationSettings } = appSetting	;

	return (
		<Validation
			config={saveDeliveryInformationFormConfig}
			initialValues={formDetails}
			transforms={saveDeliveryInformationTransformsConfig}
		>
			{({ errors, submitted, fields }) => (
				<Form.Group>
					<Form.Label>
						{PartnerLocationSettings.DeliveryDetailsFields[0].DisplayLabel}
						<span className="text-mandatory">*</span>
					</Form.Label>
					<Form.Control
						maxLength={itemsToDeliverMaxLength}
						className={submitted && errors.pickUpNote ? "invalid" : ""}
						name="pickUpNote"
						type="text"
						autoComplete="off"
						placeholder={
							PartnerLocationSettings.DeliveryDetailsFields[0].WatermarkLabel
						}
						onChange={textChange}
						value={formDetails.pickUpNote}
					/>
					<div className="float-right form-text small">
						Characters remaining:{" "}
						{formDetails.pickUpNote
							? itemsToDeliverMaxLength - formDetails.pickUpNote.length
							: itemsToDeliverMaxLength}
						/{itemsToDeliverMaxLength}
					</div>
					<div className="invalid-feedback d-block">
						{submitted && errors.pickUpNote
							? `Items to deliver ${errors.pickUpNote}`
							: ""}
					</div>
				</Form.Group>
			)}
		</Validation>
	);
};

export default ItemsToDeliver;
