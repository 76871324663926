import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useSelector } from 'react-redux';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" color={'primary'} />;

const SelectItemsToDeliver = ({checkedItems, setCheckedItems, cartItems, error}) => {
    const {DropDownItems} = useSelector((state) => state.common.appSetting.PartnerLocationSettings)

	return (
		<Autocomplete
			multiple
			options={DropDownItems ?? []}
			disableCloseOnSelect
            value={[]}
            onChange={(event, newValue) => {
                let index = checkedItems.findIndex(item=>item.Name === newValue[0].Name)
                if(index === -1)
                    setCheckedItems([...checkedItems, ...newValue])
                else setCheckedItems(checkedItems.filter(item => item.Name !== newValue[0].Name))
            }}
            id="items-to-deliver-select"
			getOptionLabel={(option) => option.Name}
            getOptionDisabled={(option) => cartItems.some(item => item.Name === option.Name) }
			renderOption={(option, { selected }) => {
                let checked = checkedItems.some(item => item.Name === option.Name)
					|| cartItems.some(item => item.Name === option.Name)
                return (
				<React.Fragment>
					<Checkbox
						icon={icon}
						checkedIcon={checkedIcon}
						style={{ marginRight: 8 }}
						checked={checked}
					/>
					{option.Name}
				</React.Fragment>
			)}}
			renderInput={(params) => {
				return (
					<TextField
					    {...params}
					    variant="outlined"
					    placeholder={cartItems.length > 0 ? 'Items selected' : "Select item"}
					    size="small"
						classes='select-input'
						error={error ? true : false}
					/>
				);
			}}
		/>
	);
};

export default SelectItemsToDeliver;
